import React from "react";
// import classNames from "classnames";
import {
  Button,
  Icon,
  Image,
  OptionMenu,
  Panel,
  Pill,
  SimpleTable,
  Text,
  Title,
  Toast,
} from "components/commons";
import { useApi, useModal, useMount } from "hooks";
import { createWebhook, getWebhooks, updateWebhook } from "apis/webhook.api";
import { FragmentA, ModuleWrapper } from "components/fragments";
// import Card from "components/commons/card/card";
// import styles from "./webhooks.module.scss";
import WebhookForm from "./webhook-form/webhook-form";
import locale from "translations";
import DeleteWebhook from "./delete-webhook";
import { EmptyBox } from "images";
import { PillType } from "enums";

import ViewWebhook from "./webhook-form/view-webhook";

const Webhooks = ({ id }) => {
  const {
    request,
    loading = true,
    error,
    result = { data: [] },
  } = useApi({
    api: getWebhooks,
    params: {
      id,
    },
    handleOwnError: true,
  });

  const createWebhookRequest = useApi({
    api: createWebhook,
    params: {
      id,
    },
  });

  const updateWebhookRequest = useApi({
    api: updateWebhook,
  });

  const webhookFormModal = useModal();
  const deleteWebhookModal = useModal();
  const viewWebhookModal = useModal();

  const { data } = result || {};

  const createWebhookButton = (
    <Button
      loading={createWebhookRequest.loading}
      className=""
      onClick={() => {
        webhookFormModal.show({
          title: "Create Webhook",
          request: async (params) => {
            webhookFormModal.close();
            params.active = true;
            await createWebhookRequest.request(params);
            await request({}, { noLoading: true });
            return {
              message: "Webhook created.",
            };
          },
        });
      }}
    >
      Create Webhook
    </Button>
  );

  useMount(() => {
    request();
  });

  return (
    <div>
      <FragmentA title="Webhooks">
        <Panel paddingless>
          {data.length || error || loading ? (
            <ModuleWrapper
              loading={loading}
              paddingless={!loading}
              error={error}
              header={
                error ? null : (
                  <div id="Webhooks" className="flex justify-between p-md">
                    <div>
                      <Title className="">Webhooks</Title>
                      <Text description className="">
                        Retrieve and store data using webhooks.
                      </Text>
                    </div>
                    {createWebhookButton}
                  </div>
                )
              }
            >
              <SimpleTable
                className="mt-md"
                addBorder
                onRowClick={({ index }) => {
                  viewWebhookModal.show({
                    data: data[index],
                    title: "Webhook Details",
                  });
                }}
                data={data.map((d) => {
                  const isActive = d.active ?? false;
                  if (!d.active) {
                    d.active = false;
                  }
                  const pillType = () => {
                    if (isActive) {
                      return PillType.Green;
                    }
                    return PillType.Gray;
                  };
                  return {
                    id: d.id,
                    name: (
                      <div>
                        <Text fontWeight="font-bold" className="truncate">
                          {d.name}
                        </Text>
                        <Text color="text-blue " size="text-sm" breakAll>
                          {d.url}
                        </Text>
                      </div>
                    ),
                    triggers: d.events.length,
                    status: (
                      <div>
                        <Pill type={pillType()}>{d.active ? "Active" : "Inactive"}</Pill>
                      </div>
                    ),
                    actions: (
                      <div className="flex flex-wrap justify-end">
                        <Icon
                          className="mr-md text-gray"
                          name="copy1"
                          fontSize="25px"
                          onClick={async () => {
                            await navigator.clipboard.writeText(JSON.stringify(d));
                            Toast({
                              content: locale.populate(locale.nCopiedInClipboard, [
                                `${d.name} webhook`,
                              ]),
                              success: true,
                              icon: "check",
                            }).open();
                          }}
                        />

                        <OptionMenu
                          className="mr-md md:mr-0"
                          loading={
                            updateWebhookRequest.submittedParams.id === d.id &&
                            updateWebhookRequest.loading
                          }
                          options={[
                            {
                              text: "Edit",
                              onClick: () => {
                                webhookFormModal.show({
                                  title: "Edit Webhook",
                                  initialState: {
                                    ...d,
                                  },
                                  onEdit: true,
                                  request: async (params) => {
                                    webhookFormModal.close();
                                    await updateWebhookRequest.request({ id: d.id, ...params });
                                    await request({}, { noLoading: true });
                                    return {
                                      message: "Webhook updated.",
                                    };
                                  },
                                });
                              },
                            },
                            {
                              text: isActive ? "Deactivate" : "Activate",
                              onClick: async () => {
                                await updateWebhookRequest.request({
                                  id: d.id,
                                  ...d,
                                  status: isActive ? false : true,
                                });
                                Toast({
                                  content: "Changes saved.",
                                  success: true,
                                  icon: "check",
                                }).open();
                                request(null, { noLoading: true });
                              },
                            },
                            {
                              text: "Delete",
                              className: "text-danger",
                              onClick: () => {
                                deleteWebhookModal.show({
                                  id: d.id,
                                  title: locale.populate(locale.askDeleteName, [d.name]),
                                });
                              },
                            },
                          ]}
                        />
                      </div>
                    ),
                  };
                })}
                columns={[
                  {
                    key: "name",
                    text: "Webhook",
                    headerClassName: "pl-md",
                    className: "py-md pl-md",
                    width: "w-1/2",
                    custom: true,
                  },
                  {
                    key: "triggers",
                    text: "Triggers",
                    className: "py-md ml-md",
                    headerClassName: "ml-md",
                  },
                  {
                    key: "status",
                    text: "Status",
                    className: "py-md",
                    custom: true,
                  },
                  {
                    key: "actions",
                    text: "",
                    custom: true,
                    className: "py-md",
                    unclickable: true,
                  },
                ]}
              />
            </ModuleWrapper>
          ) : (
            <div className="m-auto text-center w-full p-lg pb-xl">
              <div className="m-auto text-center w-full mb-md">
                <Image src={EmptyBox} className="m-auto mb-lg" />
                <Text color="text-gray" fontWeight="font-bold">
                  No Webhooks yet.
                </Text>
              </div>
              {createWebhookButton}
            </div>
          )}
        </Panel>
      </FragmentA>
      <DeleteWebhook
        {...deleteWebhookModal}
        callback={() => {
          request();
        }}
      />
      <WebhookForm
        {...webhookFormModal}
        callback={() => {
          request();
        }}
      />
      <ViewWebhook
        {...viewWebhookModal}
        editModal={(webhook) => {
          webhookFormModal.show({
            title: "Edit Webhook",
            initialState: {
              ...webhook,
            },
            onEdit: true,
            request: async (params) => {
              webhookFormModal.close();
              await updateWebhookRequest.request({ ...params, id: webhook.id });
              await request({}, { noLoading: true });
              return {
                message: "Webhook updated.",
              };
            },
          });
        }}
      />
    </div>
  );
};

export default Webhooks;
