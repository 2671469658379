import { ModuleWrapper, FragmentA } from "components/fragments";
import { HeaderA } from "components/headers";
import React, { useCallback, useContext, useMemo, useState } from "react";
import lang from "translations";
import { useModal, useForm, useMount, useApi } from "hooks";
import {
  Form,
  Panel,
  Field,
  Input,
  Title,
  // Text,
  Skeleton,
  ActionButton,
  Toast,
  UploadAvatar,
} from "components/commons";
import { Field as FieldType } from "enums";
import Validation from "services/validation.service";
import { ProfileContext } from "contexts";
import { uploadProfileAvatar, updateProfileData, updatePassword } from "apis/profile.api";
import { profileRequest } from "mappers/profile.mapper";
import ChangePassword from "./change-password/change-password";

const AccountSetting = () => {
  const [activeChangePassword, setActiveChangePassword] = useState(false);
  const { profile, loading, fetchProfile, error } = useContext(ProfileContext);

  const unsaveChangesModal = useModal();

  const { request: uploadAvatar, loading: uploading } = useApi({
    api: uploadProfileAvatar,
  });

  const { request, loading: submitting } = useApi({
    api: updateProfileData,
    paramsMapper: profileRequest,
    params: {
      profileId: profile.profileId,
    },
  });

  const changePasswordRequest = useApi({
    api: updatePassword,
    handleOwnError: {
      badrequest: true,
    },
  });

  const initialState = useMemo(() => {
    return {
      avatar: {
        value: {
          image: profile.avatar,
          file: "",
        },
        type: FieldType.ANY,
      },
      firstName: {
        name: "firstName",
        value: profile.firstName,
        type: FieldType.INPUT,
        validations: [Validation.required()],
        required: true,
        maxLength: 75,
        label: lang.firstName,
      },
      lastName: {
        name: "lastName",
        value: profile.lastName,
        type: FieldType.INPUT,
        validations: [Validation.required()],
        required: true,
        maxLength: 75,
        label: lang.lastName,
      },
      email: {
        name: "email",
        value: profile.email,
        type: FieldType.INPUT,
        validations: [Validation.required()],
        required: true,
        maxLength: 75,
        label: lang.email,
        disabled: true,
      },
    };
  }, [profile]);

  const passwordInitialState = useMemo(() => {
    return {
      oldPassword: {
        name: "oldPassword",
        value: "",
        type: FieldType.INPUT,
        validations: [Validation.required()],
        required: true,
        maxLength: 16,
        error: false,
      },
      password: {
        name: "password",
        value: "",
        type: FieldType.INPUT,
        validations: [
          Validation.required(),
          Validation.minlength(8, null, false),
          (item, { parent }) => {
            if (item.value === parent.oldPassword.value) {
              return {
                error: true,
                message: lang.currentPasswordAndNewPasswordMatched,
              };
            }
            return {
              error: false,
              message: "",
            };
          },
        ],
        required: true,
        maxLength: 16,
        error: false,
      },
      confirmPassword: {
        name: "confirmPassword",
        value: "",
        type: FieldType.INPUT,
        validations: [
          Validation.required(),
          (item, { parent }) => {
            if (item.value !== parent.password.value) {
              return {
                error: true,
                message: lang.passwordDoesNotMatch,
              };
            }
            return {
              error: false,
              message: "",
            };
          },
        ],
        required: true,
        maxLength: 16,
        error: false,
      },
    };
  }, []);

  const { fields, modifyField, dirty, submitForm, getFormValues, clearForm } = useForm({
    initialState,
  });

  const passwordForm = useForm({
    initialState: passwordInitialState,
  });

  const leavePage = useCallback(() => {
    if (dirty || passwordForm.dirty) {
      unsaveChangesModal.show({
        ok: () => {
          clearForm();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    clearForm();
  }, [dirty, unsaveChangesModal, clearForm, passwordForm.dirty]);

  const submitFormValue = useCallback(async () => {
    try {
      const values = getFormValues();
      if (activeChangePassword) {
        passwordForm.validateForm();
        if (passwordForm.isFormSubmittable) {
          await changePasswordRequest.request({
            oldPassword: passwordForm.fields.oldPassword.value,
            password: passwordForm.fields.password.value,
          });
        } else {
          return false;
        }
      }
      if (fields.avatar.value.file) {
        await uploadAvatar({
          file: fields.avatar.value.file.originFileObj,
        });
      }

      if (dirty) {
        await request(values);
        await fetchProfile();
        clearForm();
      }
      Toast({
        content: lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
      fetchProfile();
    } catch (e) {
      const passwordError = () => {
        if (e.length) {
          return e[1];
        }
        return e;
      };
      const { code, handleError } = passwordError();
      const err = {
        2001: () => {
          passwordForm.applyFieldErrors({
            oldPassword: lang.passwordIncorrect,
          });
        },
      };
      if (err[code]) {
        err[code]();
      } else {
        handleError();
      }
    }
  }, [
    getFormValues,
    request,
    fetchProfile,
    fields.avatar.value.file,
    uploadAvatar,
    passwordForm,
    activeChangePassword,
    changePasswordRequest,
    dirty,
    clearForm,
  ]);

  useMount(() => {
    fetchProfile();
  });

  return (
    <ModuleWrapper error={error} header={<HeaderA title={lang.accountSettings} />}>
      <Form unsaveChangesModal={unsaveChangesModal} onSubmit={() => {}}>
        {loading ? (
          <FragmentA title={lang.generalSettings}>
            <Skeleton />
          </FragmentA>
        ) : (
          <>
            <FragmentA title={lang.generalSettings}>
              <Panel>
                <Title md className="mb-sm">
                  {lang.profileProfile}
                </Title>
                <Field>
                  <UploadAvatar
                    phrases={[profile.firstName, profile.lastName]}
                    avatar={fields.avatar?.value?.image}
                    setAvatar={(image, file) => {
                      modifyField("avatar", {
                        value: {
                          image,
                          file,
                        },
                      });
                    }}
                  />
                </Field>
                <Field {...fields.firstName} className="mt-sm">
                  <Input required {...fields.firstName} onChange={modifyField} />
                </Field>
                <Field {...fields.lastName} className="mt-sm">
                  <Input required {...fields.lastName} onChange={modifyField} />
                </Field>
                <Field {...fields.email} className="mt-sm">
                  <Input required {...fields.email} onChange={modifyField} />
                </Field>
              </Panel>
              <ChangePassword
                form={passwordForm}
                active={activeChangePassword}
                setActive={(active) => {
                  setActiveChangePassword(active);
                  passwordForm.clearForm();
                }}
              />
            </FragmentA>
          </>
        )}
      </Form>
      <ActionButton
        showLine
        loading={submitting || uploading}
        primary={{
          onClick: () => {
            passwordForm.validateForm();
            if (dirty) {
              submitForm(submitFormValue);
            } else {
              submitFormValue();
            }
          },
          disabled: submitting || uploading || (!dirty && !passwordForm.dirty),
        }}
        secondary={{
          onClick: () => leavePage(),
        }}
      />
    </ModuleWrapper>
  );
};

export default AccountSetting;
