import React from "react";
import { Icon, Image, Text } from "components/commons";
import { Copyright, PouchnationLogoSmall } from "images";
import { FragmentMargin } from "components/fragments";
import { environment } from "environments/environment";
import lang from "translations";

const PageFooter = () => {
  return (
    <div className={"bg-washed"}>
      <div className="mt-xl mb-lg lg:mb-xxl">
        <FragmentMargin>
          <div className="grid grid-cols-1 lg:grid-cols-3 place-items-center lg:place-items-start mt-md">
            <div className="col-span-1">
              <div className="grid grid-cols-1 lg:grid-cols-2 place-items-center">
                <Image src={PouchnationLogoSmall} />
              </div>
              <div>
                <Image className="pt-4" src={Copyright} />
              </div>
              <div className="mt-lg text-center lg:text-left">
                <div className="mb-sm">
                  <Text>{lang.findUsOn}</Text>
                </div>
                <div className="mr-0 lg:mr-8 grid grid-cols-4 place-items-center lg:place-items-start">
                  {[
                    { icon: "facebook2", link: "https://www.facebook.com/pouchasia" },
                    { icon: "instagram", link: "https://www.instagram.com/pouchnation/" },
                    { icon: "linkedin", link: "https://www.linkedin.com/company/pouchnation" },
                    { icon: "youtube", link: "https://www.youtube.com/c/PouchNATION" },
                  ].map((social, index) => {
                    return (
                      <div className="mr-md" key={index}>
                        <a
                          href={social.link}
                          target="_blank"
                          rel="noreferrer"
                          className="text-md text-gray"
                        >
                          <Icon className="text-black" name={social.icon} fontSize="30px" />
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="pt-4 lg:pt-0 flex col-span-2 justify-between">
              <div className="grid grid-cols-1 lg:grid-cols-4">
                <div className="lg:pr-6">
                  <Text
                    size="text-md"
                    className="pt-2 lg:pt-0 font-bold text-black-light mb-sm text-center lg:text-left"
                  >
                    {lang.company}
                  </Text>
                  <Text className="text-gray mb-sm text-center lg:text-left">
                    <a
                      href="https://pouchnation.com"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm md:text-md"
                    >
                      {lang.aboutUs}
                    </a>
                  </Text>
                  <Text className="text-gray mb-sm text-center lg:text-left">
                    <a
                      href="https://pouchnation.com/terms/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm md:text-md"
                    >
                      {lang.termsOfUse}
                    </a>
                  </Text>

                  <Text className="text-gray mb-sm text-center lg:text-left">
                    <a
                      href="https://pouchnation.com/privacy/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm md:text-md"
                    >
                      {lang.privacy}
                    </a>
                  </Text>

                  <Text className="text-gray mb-sm text-center lg:text-left">
                    <a
                      href="https://feedback.pouchnation.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm md:text-md"
                    >
                      {lang.feedback}
                    </a>
                  </Text>

                  <Text className="text-gray mb-sm text-center lg:text-left">
                    <a
                      href="https://pouchnation.com/contact-us/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm md:text-md"
                    >
                      {lang.contactUs}
                    </a>
                  </Text>
                </div>

                <div className="pt-4 lg:pt-0 lg:pr-6">
                  <Text
                    size="text-md"
                    className="font-bold text-black-light mb-sm text-center lg:text-left"
                  >
                    {lang.ourProducts}
                  </Text>
                  <Text className="text-gray mb-sm text-center lg:text-left">
                    <a
                      href="https://pouchnation.com/pouchvenues/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm md:text-md"
                    >
                      {lang.pouchVenue}
                    </a>
                  </Text>
                  <Text className="text-gray mb-sm text-center lg:text-left">
                    <a
                      href="https://pouchnation.com/pouchevents/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm md:text-md"
                    >
                      {lang.pouchEvent}
                    </a>
                  </Text>
                  <Text className="text-gray mb-sm text-center lg:text-left">
                    <a
                      href="https://pouchpass.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm md:text-md"
                    >
                      {lang.pouchPass}
                    </a>
                  </Text>
                </div>

                <div className="lg:pr-8">
                  <Text
                    size="text-md"
                    className="pt-2 lg:pt-0 font-bold text-black-light mb-sm text-center lg:text-left"
                  >
                    {lang.web}
                  </Text>

                  <Text className="text-gray mb-sm text-center lg:text-left">
                    <a
                      href="https://accounts.pouchnation.com"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm md:text-md"
                    >
                      {lang.accounts}
                    </a>
                  </Text>
                  <Text className="text-gray mb-sm text-center lg:text-left">
                    <a
                      href={`${environment.CONNECTOR_URL}/home`}
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm md:text-md"
                    >
                      {lang.pouchConnect}
                    </a>
                  </Text>
                  <Text className="text-gray mb-sm text-center lg:text-left">
                    <a
                      href="https://venue.pouchnation.com"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm md:text-md"
                    >
                      {lang.venueDashboard}
                    </a>
                  </Text>
                  <Text className="text-gray mb-sm text-center lg:text-left">
                    <a
                      href="https://events.pouchnation.com"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm md:text-md"
                    >
                      {lang.eventDashboard}
                    </a>
                  </Text>
                  <Text className="text-gray mb-sm text-center lg:text-left">
                    <a
                      href="https://pouchpass.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm md:text-md"
                    >
                      {lang.pouchPass}
                    </a>
                  </Text>
                </div>

                <div className="lg:pl-8">
                  <Text
                    size="text-md"
                    className="pt-2 lg:pt-0 font-bold text-black-light mb-sm text-center lg:text-left"
                  >
                    {lang.resources}
                  </Text>

                  {/* <Text className="text-gray mb-sm text-center lg:text-left">
                    <a
                      href="https://feedback.pouchnation.com/roadmap"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm md:text-md"
                    >
                      {lang.glossary}
                    </a>
                  </Text> */}

                  <Text className="text-gray mb-sm text-center lg:text-left">
                    <a
                      href="https://feedback.pouchnation.com/roadmap"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm md:text-md"
                    >
                      {lang.roadMap}
                    </a>
                  </Text>
                  <Text className="text-gray mb-sm text-center lg:text-left">
                    <a
                      href="https://status.pouchnation.com"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm md:text-md"
                    >
                      {lang.systemStatus}
                    </a>
                  </Text>

                  {/* <Text className="text-gray mb-sm text-center lg:text-left">
                    <a
                      href="https://feedback.pouchnation.com/roadmap"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm md:text-md"
                    >
                      {lang.apis}
                    </a>
                  </Text> */}
                  {/* <Text className="text-gray mb-sm text-center lg:text-left">
                    <a
                      href="https://feedback.pouchnation.com/roadmap"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm md:text-md"
                    >
                      {lang.support}
                    </a>
                  </Text> */}
                </div>
              </div>
            </div>
          </div>
        </FragmentMargin>
      </div>
    </div>
  );
};

export default PageFooter;
