import React from "react";
import { Title, Text, ButtonLink, Button } from "components/commons";
import { useRouter } from "hooks";
import classNames from "classnames";
import { StyleType } from "enums";

const HeaderB = ({
  title,
  description,
  returnText,
  returnPath,
  onClick,
  loading,
  button,
  secondaryButton,
  className,
}) => {
  const { location } = useRouter();
  const { state } = location;
  const { fromList } = state || {};
  const {
    text,
    disabled: primaryDisabled = false,
    loading: primaryLoading = false,
    onClick: onClickPrimary,
    iconPrefix,
    type,
  } = button || {};

  const {
    text: secondaryButtonText,
    disabled: secondaryDisabled = false,
    loading: secondaryLoading = false,
    onClick: onClickSecondary,
    iconPrefix: secondaryIconPrefix,
  } = secondaryButton || {};

  return (
    <div>
      <div>
        <ButtonLink
          loading={loading}
          icon="left-arrow"
          iconClassName="text-xxl"
          className="-mx-2 text-sm"
          size={"text-xs"}
          path={fromList ? null : returnPath}
          onClick={
            fromList
              ? () => {
                  window.history.back();
                }
              : onClick
          }
          flex={false}
        >
          {returnText}
        </ButtonLink>
      </div>
      <div className={classNames(className, "md:flex items-center justify-between")}>
        <div>
          <Title xl className="font-semibold">
            {title}
          </Title>
          {description && <Text className="pb-sm">{description}</Text>}
        </div>
        <div>
          {secondaryButton && (
            <Button
              type={StyleType.Secondary}
              loading={secondaryLoading}
              disabled={secondaryDisabled}
              className="ml-0 mt-sm md:ml-md mt-0"
              onClick={
                onClickSecondary
                  ? () => {
                      onClickSecondary();
                    }
                  : null
              }
              iconPrefix={secondaryIconPrefix}
            >
              {secondaryButtonText}
            </Button>
          )}
          {button && (
            <Button
              type={type}
              loading={primaryLoading}
              disabled={primaryDisabled}
              className="ml-0 mt-sm md:ml-md mt-0"
              onClick={
                onClickPrimary
                  ? () => {
                      onClickPrimary();
                    }
                  : null
              }
              iconPrefix={iconPrefix}
            >
              {text}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderB;
