import {
  DiscountStatus,
  StaffRole,
  StaffStatus,
  TransactionType,
  VoucherStatus,
  ProductType,
  VoucherType,
  GuestStatus,
  TransactionStatus,
  TapStatus,
  Field,
  BanType,
  ReceiptName,
  InventoryMovementType,
  ProductStockLevel,
  ProductCreationType,
  ApprovalStatus,
} from "enums";
import lang from "translations";

export const prettifyProductStockLevel = (stockLevel) => {
  return (
    {
      [ProductStockLevel.InStock]: "In Stock",
      [ProductStockLevel.CheckStock]: "Check Stocks",
      [ProductStockLevel.ReorderStock]: "Reorder Stocks",
      [ProductStockLevel.NoStock]: "No Stocks",
    }[stockLevel] || ""
  );
};

export const prettifyStaffRole = (staffRole) => {
  return (
    {
      [StaffRole.SellOnPos]: "Sell on POS",
      [StaffRole.TopupCredits]: "Topup Credits and Return Credits",
      [StaffRole.RegisterGuests]: "Register Guests",
      [StaffRole.Manager]: "Manager",
    }[staffRole] || ""
  );
};

export const prettifyStaffStatus = (staffStatus) => {
  return (
    {
      [StaffStatus.Awaiting]: "Awaiting invite response",
      [StaffStatus.Inactive]: "Inactive",
    }[staffStatus] || ""
  );
};

export const prettifyDiscountStatus = (discountStatus) => {
  return (
    {
      [DiscountStatus.Active]: "Active",
      [DiscountStatus.Inactive]: "Inactive",
      [DiscountStatus.Expired]: "Expired",
    }[discountStatus] || ""
  );
};

export const prettifyVoucherStatus = (voucherStatus) => {
  return (
    {
      [VoucherStatus.Active]: "Active",
      [VoucherStatus.Inactive]: "Inactive",
      [VoucherStatus.Expired]: "Expired",
    }[voucherStatus] || ""
  );
};

export const prettifyTransactionType = (transactionType) => {
  return (
    {
      [TransactionType.TOPUP]: "Topup Credits",
      [TransactionType.SALE]: "Sale",
      [TransactionType.RETURN]: "Return Credits",
      [TransactionType.MIXED]: "Mixed",
      [TransactionType.REDEEM]: "Redeem",
      [TransactionType.REMOVE]: "Remove",
      [TransactionType.ISSUE_FOC]: "Issue Free Credits",
      [TransactionType.REMOVE_FOC]: "Remove Free Credits",
    }[transactionType] || ""
  );
};

export const prettifyProductType = (productType, count) => {
  const isPlural = count > 1;
  return (
    {
      [ProductType.Standard]: lang.standard,
      [ProductType.Variant]: isPlural ? lang.variants : lang.variant,
      [ProductType.Composite]: lang.composite,
      [ProductType.SupplyItem]: lang.supplyItem,
    }[productType] || ""
  );
};

export const prettifyProductCreationType = (productCreationType, count) => {
  return (
    {
      [ProductCreationType.Whole]: lang.whole,
      [ProductCreationType.ProductSet]: lang.productSet,
      [ProductCreationType.Assemble]: lang.assemble,
    }[productCreationType] || ""
  );
};

export const prettifyVoucherType = (voucherType) => {
  return (
    {
      [VoucherType.Issue]: "Issue",
      [VoucherType.Redeem]: "Redeem",
      [VoucherType.Remove]: "Remove",
      [VoucherType.Usage]: "Usage",
    }[voucherType] || ""
  );
};

export const prettifyGuestStatus = (guestStatus) => {
  return (
    {
      [GuestStatus.CHECKED_IN]: "Checked-In",
      [GuestStatus.CHECKED_OUT]: "Checked-Out",
    }[guestStatus] || ""
  );
};

export const prettifyTransactionStatus = (transactionStatus) => {
  return (
    {
      [TransactionStatus.SUCCESS]: "Success",
      [TransactionStatus.VOID]: "Voided",
    }[transactionStatus] || ""
  );
};

export const prettifyTapStatus = (tapStatus) => {
  return (
    {
      [TapStatus.Success]: "Success",
      [TapStatus.Failed]: "Failed",
    }[tapStatus] || ""
  );
};

export const prettifyFieldType = (type) => {
  return (
    {
      [Field.INPUT]: lang.textbox,
      [Field.DROPDOWN]: lang.dropdown,
      [Field.CHECKBOX]: lang.checkbox,
      [Field.RADIO]: lang.radioButton,
      [Field.COUNTRY]: lang.country,
      [Field.NATIONALITY]: lang.nationality,
    }[type] || lang.textbox
  );
};

export const prettifyBanType = (type) => {
  return (
    {
      [BanType.LOST_TAG]: lang.lostTag,
      [BanType.DEFECTIVE_TAG]: lang.defectiveTag,
      [BanType.OTHERS]: lang.others,
    }[type] || ""
  );
};

export const prettifyReceiptOptions = (receiptName) => {
  return (
    {
      [ReceiptName.VenueName]: "Venue Name",
      [ReceiptName.BusinessName]: "Business Name",
      [ReceiptName.BusinessAddress]: "Business Address",
      [ReceiptName.BusinessContactNumber]: "Business Contact Number",
      [ReceiptName.TaxNumber]: "Tax Number",
      [ReceiptName.DeviceId]: "Device ID",
      [ReceiptName.SerialNumber]: "Serial Number",
      [ReceiptName.ReceiptNumber]: "Receipt Number",
      [ReceiptName.DateAndTimeOfTransaction]: "Date and Time of Transaction",
      [ReceiptName.VenueLocation]: "Venue Location",
      [ReceiptName.StaffNameAndOrTagId]: "Staff Name and/or Tag ID",
      [ReceiptName.PosProvider]: "POS Provider",
      [ReceiptName.GuestNameAndOrTagId]: "Guest Name and/or Tag ID",
      [ReceiptName.TransactionId]: "Transaction ID",
      [ReceiptName.ItemTax]: "Item Tax",
      [ReceiptName.GuestsPreviousBalanceTotalPaidAndNewBalance]:
        "Guests Previous Balance, Total Paid, and New Balance",
    }[receiptName] || ""
  );
};

export const prettifyInventoryMovementType = (movementType) => {
  return (
    {
      [InventoryMovementType.AddedStock]: "Added Stock",
      [InventoryMovementType.RemovedStock]: "Removed Stock",
      [InventoryMovementType.CreatedSupplyItem]: "Created Supply Item",
      [InventoryMovementType.TrackedInventory]: "Tracked Inventory",
      [InventoryMovementType.Sale]: "Sale",
      [InventoryMovementType.SaleReturn]: "Sale Return",
      [InventoryMovementType.Mixed]: "Mixed",
      [InventoryMovementType.Redeemed]: "Redeemed",
    }[movementType] || ""
  );
};

export const prettifyApprovalStatus = (approvalStatus) => {
  return (
    {
      [ApprovalStatus.Enabled]: "Enabled",
      [ApprovalStatus.Pending]: "Pending",
    }[approvalStatus] || ""
  );
};
