export const locale = {
  somethingWentWrong: "Something went wrong.",
  looksLikeYouEncounteredSomething:
    "Looks like you encountered an error, we track these errors automatically. For the mean time, try refreshing.",
  ok: "Ok",
  getStarted: "Get Started",
  myApps: "My Apps",
  docs: "Docs",
  save: "Save",
  cancel: "Cancel",
  discard: "Discard",
  noResultsFound: "No Results Found",
  appDetails: "App Details",
  placeholder: "Placeholder",
  appCreated: "App Created",
  createNewApp: "Create New App",
  provideYourAppBasicDetails: "Provide your app's basic details",
  appName: "App Name",
  accountSettings: "Account Settings",
  logout: "Logout",
  integrations: "Integrations",
  pouchConnect: "PouchCONNECT",
  provideXCredentials: "Provide {0} Credentials",
  addFieldParameter: "Add Field/Parameter",
  integrate: "Integrate",
  descriptionPlaceholder: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  delete: "Delete",
  view: "View",
  clientSecret: "Client Secret",
  clientID: "Client ID",
  nCopiedInClipboard: "{0} copied in clipboard.",
  authentication: "Authentication",
  copyAllAuthenticationAsJSONObj: "Copy your credentials as JSON Object.",
  areYouSureYouWantToDeleteThisApp: "Are you sure you want to delete this app?",
  askDeleteName: "Delete '{0}'?",
  edit: "Edit",
  credentials: "Credentials",
  areYouSureYouWantToDeleteThisWebhook: "Are you sure you want to delete this webhook?",
  accessToken: "Access Token",
  venue: "Venue",
  loginSessionEnded: "Login Session Ended",
  loginHasEnded: "Your login session has already ended. Please login again.",
  myAppsDesc: "Add and manage your apps to integrate with our system.",
  createApp: "Create App",
  invalidFileType: "Invalid file type. Allowed file types are JPG or PNG.",
  createWebhook: "Create Webhook",
  findUsOn: "Find us on",
  allRightsReserved: "All rights reserved.",
  copyrightYear: "Copyright © 2021",
  pouchnation: "PouchNATION",
  buildWithPouchConnect: "Build with PouchCONNECT",
  welcomeToPouchnationDeveloperHub: "Welcome to PouchNATION’s Developer Hub. Discover our APIs and learn how you can integrate with our cashless and guest management system.",
  startIntegrating: "Start integrating your system with our APIs.",
  support: "Support",
  viewDocumentation: "View Documentation",
  needHelp: "Need help? We've got your back.",
  askOurTechSupportTeam: "Ask our tech support team any question in our Discord channel!",
  joinUsInDiscord: "Join us in Discord",
  readyToBuild: "Ready to build?",
  createMyApp: "Create My App",
  bookingsApi: "Bookings API",
  accountingApi: "Accounting API",
  paymentApi: "Payment API",
  syncBookingsAndReservations: "Sync bookings and reservations from your system to our bookings for our PouchEVENTS and PouchVENUES platforms.",
  synchronizeYourAccounting: "Synchronize your Accounting Ledger to our platforms with other platforms such as Mews, Cloudbeds, and many more.",
  createAndAuthorizeYourOwnPayments: "Create and authorize your own payments with our Payments API. We provide a wide-range of payment gateways where you can easily integrate.",
  company: "Company",
  aboutUs: "About Us",
  termsOfUse: "Terms of Use",
  privacy: "Privacy",
  feedback: "Feedback",
  contactUs: "Contact Us",
  ourProducts: "Our Products",
  pouchVenue: "PouchVENUE",
  pouchEvent: "PouchEVENT",
  pouchPass: "PouchPASS",
  web: "Web",
  accounts: "Accounts",
  venueDashboard: "Venue Dashboard",
  eventDashboard: "Event Dashboard",
  resources: "Resources",
  roadMap: "Roadmap",
  systemStatus: "System Status",
  glossary: "Glossary",
  apis: "APIs",
  releaseNotes: "Release Notes",
  login: "Login",
  createAnAccount: "Create an Account",
};

export default Object.freeze(locale);
