import React from "react";
import classnames from "classnames";
import { Container, PageError, Skeleton } from "components/commons";
import styles from "./fragment.module.scss";
import { FragmentMargin } from ".";

const ModuleWrapper = ({
  children,
  width = "full",
  header,
  error,
  loading,
  loader,
  paddingless = false,
}) => {
  const render = () => {
    if (loading) {
      return (
        loader || (
          <Container paddingless={paddingless}>
            <Skeleton />
          </Container>
        )
      );
    }

    if (error) {
      return <PageError />;
    }

    return children;
  };
  return (
    <FragmentMargin className="p-4 md:p-0 pb-lg">
      <div
        className={classnames(
          {
            "xl:w-10/12 lg:w-11/12 md:w-full sm:w-full": width === "medium",
            "w-full": width === "full",
            "xl:py-md 2xl:py-xl": !paddingless,
          },
          styles.pageContent
        )}
      >
        {header}
        {render()}
      </div>
    </FragmentMargin>
  );
};

export default ModuleWrapper;
