import React from "react";
import { Switch as AntSwitch } from "antd";
import classnames from "classnames";

const Switch = ({ value, size = "default", onChange, name, loading, disabled, className }) => {
  return (
    <div>
      <AntSwitch
        className={classnames({ "bg-pelorous": value }, className)}
        checked={value}
        onChange={() => {
          onChange(name, {
            value: !value,
          });
        }}
        size={size}
        loading={loading}
        disabled={disabled}
      />
    </div>
  );
};

export default Switch;
