import enumeration from "./enumeration";

const TimeUnit = {
  Hour: "HOUR",
  Day: "DAY",
  Month: "MONTH",
  Year: "YEAR",
};

export default enumeration(TimeUnit);
