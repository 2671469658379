import { appPaths } from "./app.path";
import { authPaths } from "./auth.path";
import { settingPaths } from "./setting.path";
import { integrationPaths } from "./integration.path";
import { homePaths } from "./home.path";

const preparePaths = ({ prefix, paths = {} }) => {
  let newPaths = {};

  for (const [k, path] of Object.entries(paths)) {
    if (typeof path === "function") {
      newPaths[k] = (id) => `/${prefix}/${path(id)}`.replace(/\/+/g, "/");
    } else if (path) {
      newPaths[k] = `/${prefix}/${path}`.replace(/\/+/g, "/");
    } else {
      newPaths[k] = `/${prefix}`.replace(/\/+/g, "/");
    }
  }

  newPaths[prefix.toString().toUpperCase()] = `/${prefix}`;
  return newPaths;
};

const Path = {
  ...preparePaths({ prefix: "home", paths: homePaths }),
  ...preparePaths({ prefix: "auth", paths: authPaths }),
  ...preparePaths({ prefix: "setting", paths: settingPaths }),
  ...preparePaths({ prefix: "apps", paths: appPaths }),
  ...preparePaths({ prefix: "integrations", paths: integrationPaths }),
  SLASH: "/",
  DOCS: "/documentation",
  // INTEGRATIONS: "/integrations",
};

export default Path;
