import { deleteWebhook } from "apis/webhook.api";
import { ActionButton, Modal, Text } from "components/commons";
import { StyleType } from "enums";
import { useApi } from "hooks";
import React from "react";
import locale from "translations";

const DeleteWebhook = ({ id, callback, ...modal }) => {
  const { request, loading } = useApi({
    api: deleteWebhook,
    params: {
      id,
    },
  });

  return (
    <Modal
      {...modal}
      actionContent={
        <ActionButton
          className="p-lg"
          loading={loading}
          primary={{
            text: locale.delete,
            type: StyleType.Danger,
            onClick: async () => {
              await request();
              modal.close();
              if (callback) {
                callback();
              }
            },
          }}
          secondary={{
            text: locale.cancel,
            onClick: () => {
              modal.close();
            },
          }}
        />
      }
    >
      <Text>{locale.areYouSureYouWantToDeleteThisWebhook}</Text>
    </Modal>
  );
};

export default DeleteWebhook;
