import React from "react";
import { Route, Switch } from "react-router-dom";
import { Path } from "paths";
import { useMount } from "hooks";
import { setPageTitle } from "services";
import IntegrationList from "modules/integration/integration-list/integration-list.module";
// import ViewIntegrationDetails from "modules/integration/view-integration-details/view-integration-details.module";

const IntegrationPage = () => {
  useMount(() => {
    setPageTitle("integrations");
  });

  return (
    <div>
      <Switch>
        {/* <Route exact path={Path.INTEGRATION_ID} component={ViewIntegrationDetails} /> */}
        <Route exact path={Path.INTEGRATIONS} component={IntegrationList} />
      </Switch>
    </div>
  );
};

export default IntegrationPage;
