import React, { useCallback, useMemo, useState } from "react";
import {
  Button,
  ButtonLink,
  Dropdown,
  Icon,
  Image,
  Text,
  UserNav,
  UserNavB,
} from "components/commons";
import { addQueryParam, redirectTo } from "services";
import classNames from "classnames";
import styles from "./page-header.module.scss";
import { useRouter } from "hooks";
import { Path } from "paths";
import { environment } from "environments/environment";
import { PouchConnectLogo } from "images";
import { Drawer } from "antd";
import lang from "translations";
import { StyleType } from "enums";

const PageHeader = ({ logoRedirectUrl, hasUserNav = true }) => {
  const { history } = useRouter();
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const hasSession = useMemo(() => {
    return localStorage.getItem("accessToken");
  }, []);

  const goToDocumentation = useCallback(() => {
    window.location.href = environment.DOCUMENTATION_URL;
  }, []);

  const loginUrl = addQueryParam(environment.AUTH.login, {
    clientId: environment.AUTH.clientId,
    cognitoClientId: environment.AUTH.cognitoClientId,
    responseType: "token",
    scope: "booking/user+connector/user+email+openid+profile",
    redirectUrl: `${environment.POUCH_ACCOUNTS}/sign/connector`,
  });

  const registerUrl = addQueryParam(environment.AUTH.register, {
    clientId: environment.AUTH.clientId,
    cognitoClientId: environment.AUTH.cognitoClientId,
    responseType: "token",
    scope: "booking/user+connector/user+email+openid+profile",
    redirectUrl: `${environment.URL}${Path.AUTH}`,
  });

  const goToSignIn = useCallback(() => {
    redirectTo(loginUrl);
  }, [loginUrl]);

  const goToCreateAccount = useCallback(() => {
    redirectTo(registerUrl);
  }, [registerUrl]);

  const menu = useMemo(() => {
    const item = [];
    if (hasSession) {
      item.push({
        text: "My Apps",
        onClick: () => {
          history.push(Path.APP);
        },
      });
    }
    return [
      ...item,
      {
        text: "APIs",
        type: "DROPDOWN",
        items: [
          {
            text: "Bookings API",
            onClick: () => {
              window.location.href = "https://pouchconnect.readme.io/reference/bookings-api";
            },
          },
          {
            text: "Accounting API",
            onClick: () => {
              window.location.href = "https://pouchconnect.readme.io/reference/accounting-api";
            },
          },
          {
            text: "Payment API",
            onClick: () => {
              window.location.href = "https://pouchconnect.readme.io/reference/payments-api";
            },
          },
        ],
      },
      {
        text: "Docs",
        onClick: () => {
          goToDocumentation();
        },
      },
      {
        text: "Support",
        onClick: () => {
          window.open("https://discord.gg/5JaQUq5rU9");
        },
      },
    ];
  }, [hasSession, history, goToDocumentation]);

  return (
    <div
      className={classNames(
        styles.container,
        "bg-washed h-15 min-h-15 z-20 w-full flex items-center justify-between fixed"
      )}
    >
      <div className={classNames(styles.content, "flex justify-between")}>
        <div className="flex content-center items-center pt-2 md:pt-0">
          <span className="flex text-lg text-black font-semibold mr-xs">
            <Image
              src={PouchConnectLogo}
              className={"px-4 md:px-0 -mt-2 md:-mt-0 max-w-r12 sm:max-w-r12 cursor-pointer"}
              onClick={() => {
                if (logoRedirectUrl) {
                  redirectTo(logoRedirectUrl);
                } else {
                  history.push("/");
                }
              }}
            />
          </span>

          <div className="fixed visible md:invisible right-4 -mt-2">
            <Icon
              color="text-gray-lighter text-2xl"
              className="w-8 h-8 -mt-5"
              name="menu"
              onClick={showDrawer}
            />
            <Drawer
              title="PouchCONNECT"
              placement="right"
              inlineCollapsed={true}
              onClose={onClose}
              visible={visible}
              closable={false}
              bodyStyle={{ padding: 0 }}
            >
              <div className="grid grid-cols-1 gap-1 justify-items-stretch ">
                {hasSession ? (
                  <div className="bg-gray-lightest">
                    <ButtonLink
                      size="text-base"
                      className={classNames("pl-4 py-2 font-semibold text-pelorous-darkest")}
                      onClick={() => {
                        history.push(Path.APP);
                      }}
                    >
                      {lang.myApps}
                    </ButtonLink>
                  </div>
                ) : null}
                <div className="grid grid-cols-2 gap-4 bg-gray-lightest items-center cursor-pointer">
                  <div>
                    <Text
                      className={classNames(
                        "text-base pl-4 py-2 font-semibold text-pelorous-darkest"
                      )}
                    >
                      {lang.apis}
                    </Text>
                  </div>

                  <div className="place-self-end py-2">
                    <Icon className="text-xl" name={"arrow-down1"} />
                  </div>
                </div>

                <ButtonLink
                  size="text-base"
                  className={classNames("pl-4 py-2")}
                  onClick={() => {
                    window.location.href = "https://pouchconnect.readme.io/reference/bookings-api";
                  }}
                >
                  <Icon className="text-gray text-md ml-xs" name={"arrow-right1"} />
                  {lang.bookingsApi}
                </ButtonLink>
                <ButtonLink
                  size="text-base"
                  className={classNames("pl-4 py-2")}
                  onClick={() => {
                    window.location.href =
                      "https://pouchconnect.readme.io/reference/accounting-api";
                  }}
                >
                  <Icon className="text-gray text-md ml-xs" name={"arrow-right1"} />
                  {lang.accountingApi}
                </ButtonLink>
                <ButtonLink
                  size="text-base"
                  className={classNames("pl-4 py-2")}
                  onClick={() => {
                    window.location.href = "https://pouchconnect.readme.io/reference/payments-api";
                  }}
                >
                  <Icon className="text-gray text-md ml-xs" name={"arrow-right1"} />
                  {lang.paymentApi}
                </ButtonLink>

                <ButtonLink
                  size="text-base"
                  className={classNames(
                    "bg-gray-lightest pl-4 py-2 font-semibold text-pelorous-darkest"
                  )}
                  onClick={() => {
                    window.location.href = "https://pouchconnect.readme.io/reference/payments-api";
                  }}
                >
                  {lang.docs}
                </ButtonLink>
                <ButtonLink
                  size="text-base"
                  className={classNames(
                    "bg-gray-lightest pl-4 py-2 font-semibold text-pelorous-darkest"
                  )}
                  onClick={() => {
                    goToDocumentation();
                  }}
                >
                  {lang.support}
                </ButtonLink>
              </div>

              <div className="grid justify-items-center">
                {!hasSession && (
                  <Button
                    className="pt-96"
                    type={StyleType.Link}
                    onClick={() => {
                      goToSignIn();
                    }}
                  >
                    <Text className="text-base text-black">{lang.login}</Text>
                  </Button>
                )}
              </div>
              <div className="pt-1 grid justify-items-stretch">
                {!hasSession && (
                  <Button type={StyleType.Link} onClick={goToCreateAccount}>
                    <Text className="text-base text-black">{lang.createAnAccount}</Text>
                  </Button>
                )}

                {hasSession && hasUserNav && <UserNavB />}
              </div>
            </Drawer>
          </div>

          <div className="flex invisible md:visible">
            {menu.map((item, key) => {
              if (item.items?.length) {
                return (
                  <Dropdown
                    key={key}
                    options={item.items}
                    className={classNames(styles.headerItem, "text-left")}
                  >
                    <div key={key} className={classNames("mr-xl", styles.headerNav, "text-left")}>
                      {item.text}
                      <Icon color="text-gray" name="down-caret" />
                    </div>
                  </Dropdown>
                );
              }
              return (
                <div
                  key={key}
                  className={classNames("mr-xl", styles.headerNav, styles.headerItem)}
                  onClick={() => {
                    if (item.onClick) {
                      item.onClick();
                    }
                  }}
                >
                  {item.text}
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex invisible md:visible">
          {!hasSession && (
            <div
              className={classNames(styles.headerNav, "flex items-center mr-md")}
              onClick={() => {
                goToSignIn();
              }}
            >
              {lang.login}
            </div>
          )}

          {!hasSession && (
            <Button onClick={goToCreateAccount}>
              <div className={styles.headerNavText}>{lang.createAnAccount}</div>
            </Button>
          )}

          {hasSession && hasUserNav && <UserNav />}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
