import { Delete, Get, Post, Put } from "services";

export const getApplications = async (body) => {
  const res = await Get(`applications`, body);
  return res;
};

export const getApplicationByID = async ({ id }) => {
  const res = await Get(`applications/${id}`);
  return res;
};

export const deleteApplication = async ({ id }) => {
  const res = await Delete(`applications/${id}`);
  return res;
};

export const updateApplication = async ({ id, ...body }) => {
  const res = await Put(`applications/${id}`, body);
  return res;
};

export const createApplication = async (body) => {
  const res = await Post(`applications`, body);
  return res;
};
