import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Modal,
  ActionButton,
  Form,
  Field,
  Input,
  TextArea,
  Toast,
  Checkbox,
  Title,
  Text,
  Switch,
} from "components/commons";
import lang from "translations";
import classnames from "classnames";
import styles from "./style.module.scss";
import { useForm, useModal } from "hooks";
import { useCallback } from "react";
import initialFormState from "./webhook-form.state";
import { AppContext } from "contexts";
import { capitalize } from "services";
import Validation from "services/validation.service";
import locale from "translations";
import DeleteWebhook from "../delete-webhook";
const WebhookForm = ({
  request,
  loading,
  active,
  close,
  initialState,
  callback,
  title,
  onEdit = false,
}) => {
  const { globalErrorModal } = useContext(AppContext);

  const triggers = [
    {
      type: "booking",
      subTypes: [
        {
          name: "created",
          label: "Created",
        },
        {
          name: "updated",
          label: "Updated",
        },
        {
          name: "canceled",
          label: "Canceled",
        },
      ],
    },
    {
      type: "guest",
      subTypes: [
        {
          name: "created",
          label: "Created",
        },
        {
          name: "updated",
          label: "Updated",
        },
        {
          name: "deleted",
          label: "Deleted",
        },
      ],
    },
    {
      type: "transaction",
      subTypes: [
        {
          name: "checkout",
          label: "Checkout",
        },
        {
          name: "postpaid",
          label: "Postpaid",
        },
        {
          name: "postpaid_void",
          label: "Postpaid Void",
        },
        {
          name: "prepaid",
          label: "Prepaid",
        },
        {
          name: "prepaid_void",
          label: "Prepaid Void",
        },
        {
          name: "customer_booking_registration",
          label: "Customer Booking Registration",
        },
        {
          name: "customer_booking_update",
          label: "Customer Booking Update",
        },
        {
          name: "topup",
          label: "Topup",
        },
        {
          name: "topup_void",
          label: "Topup Void",
        },
        {
          name: "cashback",
          label: "Cashback",
        },
        {
          name: "additional_charge_prepaid",
          label: "Additional Charge Prepaid",
        },
        {
          name: "additional_charge_postpaid",
          label: "Additional Charge Postpaid",
        },
        {
          name: "tip_prepaid",
          label: "Tip Prepaid",
        },
        {
          name: "tip_postpaid",
          label: "Tip Postpaid",
        },
        {
          name: "vg_credits_received",
          label: "MultiProperty Credits Received",
        },
        {
          name: "vg_credits_kept",
          label: "MultiProperty Credits Kept",
        },
      ],
    }
  ]

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);
  const deleteWebhookModal = useModal();
  const { fields, modifyField, modifyForm, submitForm, getFormValues, clearForm, dirty } = useForm({
    initialState: formState,
  });
  const [usingAuth, setUsingAuth] = useState(false);
  const handleSubmit = useCallback(async () => {
    const params = getFormValues();
    setUsingAuth(false);
    if (!params.authentication) {
      params.authUsername = null;
      params.authPassword = null;
    }
    try {
      const res = await request({ status: initialState?.active, ...params });
      clearForm();
      close();
      Toast({
        content: res.message,
        success: true,
        icon: "check",
      }).open();
      if (callback) {
        callback();
      }
    } catch (error) {
      console.log(error);
      if (error.handleError) {
        error.handleError();
      }
    }
  }, [getFormValues, request, clearForm, close, callback, initialState]);

  useEffect(() => {
    if (active) {
      clearForm();
    }
    // eslint-disable-next-line
  }, [active]);

  const validateAuth = (usingAuth) => {
    if (
      usingAuth &&
      (!fields.authUsername.value ||
        !fields.authPassword.value ||
        fields.authUsername.value === "" ||
        fields.authPassword.value === "")
    ) {
      modifyForm({
        authUsername: { validations: [Validation.required()] },
        authPassword: { validations: [Validation.required()] },
      });
      return false;
    }
    modifyForm({
      authUsername: { error: false, dirty: false, validations: [] },
      authPassword: { error: false, dirty: false, validations: [] },
    });
    return true;
  };

  return (
    <>
      <Modal
        active={globalErrorModal.active ? false : active}
        className={classnames(styles.modal)}
        title={title}
        onClose={close}
        close={close}
        width="50%"
        actionContent={
          <ActionButton
            showLine
            loading={loading}
            className="px-md pb-md"
            secondary={{
              text: lang.cancel,
              onClick: () => {
                clearForm();
                close();
              },
            }}
            primary={{
              text: onEdit ? lang.save : lang.createWebhook,
              loading,
              disabled: !dirty,
              onClick: async () => {
                if (usingAuth) {
                  const res = await validateAuth(usingAuth);
                  if (res) {
                    submitForm(handleSubmit);
                  }
                } else {
                  submitForm(handleSubmit);
                }
              },
            }}
            danger={
              onEdit
                ? {
                    text: "Delete",
                    onClick: () => {
                      deleteWebhookModal.show({
                        id: fields.webhookId.value,
                        title: locale.populate(locale.askDeleteName, [fields.name.value]),
                      });
                      close();
                    },
                  }
                : false
            }
          />
        }
      >
        <Form onSubmit={handleSubmit}>
          <Field {...fields.name}>
            <Input
              required
              {...fields.name}
              className={classnames("rounded")}
              placeholder=""
              onChange={modifyField}
            />
          </Field>
          <Field {...fields.url}>
            <TextArea required {...fields.url} placeholder="" onChange={modifyField} />
          </Field>
          {onEdit && (
            <Field {...fields.status}>
              <div className="flex items-center gap-2">
                <Switch {...fields.status} onChange={modifyField} />
                <Title sm>Activate?</Title>
              </div>
            </Field>
          )}
          <div>
            {triggers.map((trigger, index) => {
              const eventFields = [];
              for (let i = 0; i < trigger.subTypes.length; i += 5) {
                const group = trigger.subTypes.slice(i, i + 5).map(subType => {
                  const val = `${trigger.type}.${subType.name}`;
                  const selected = fields.events.value.includes(val);
                  return (
                    <div className="flex py-xs pr-lg">
                      <Checkbox
                        value={selected}
                        onChange={() => {
                          const value = !selected
                            ? [...fields.events.value, val]
                            : fields.events.value.filter(v => v !== val);
                          console.log(value);
                          modifyField(fields.events.name, { value });
                        }}
                      />
                      <Text className="ml-sm">
                        {subType.label}
                      </Text>
                    </div>
                  );
                });
                eventFields.push(<div className="column">{group}</div>);
              }
              return (
                <Field
                  {...fields.events}
                  label={`${capitalize(trigger.type.replace("_", " "))} Triggers:`}
                  key={index}
                >
                  <div className="flex">{eventFields}</div>
                </Field>
              );
            })}
          </div>
          <div className="mt-md">
            <Field {...fields.authentication}>
              <div className="flex items-center gap-1">
                <Checkbox
                  {...fields.authentication}
                  onChange={(name, { value }) => {
                    modifyForm({
                      [name]: { value: value },
                      authUsername: !value && { dirty: false, error: false, validations: null },
                      authPassword: !value && { dirty: false, error: false, validations: null },
                    });
                    setUsingAuth(value);
                  }}
                />
                <Title sm className="mt-xs">
                  Use basic authentication for your URL?
                </Title>
              </div>
            </Field>
            {fields.authentication.value && (
              <>
                <Field {...fields.authUsername}>
                  <Input
                    {...fields.authUsername}
                    placeholder=""
                    onChange={(name, { value }) => {
                      modifyField(name, { value: value });
                      setUsingAuth(true);
                    }}
                  />
                </Field>
                <Field {...fields.authPassword}>
                  <Input
                    {...fields.authPassword}
                    placeholder=""
                    onChange={(name, { value }) => {
                      modifyField(name, { value: value });
                      setUsingAuth(true);
                    }}
                  />
                </Field>
              </>
            )}
          </div>
        </Form>
      </Modal>
      <DeleteWebhook
        {...deleteWebhookModal}
        callback={() => {
          callback();
        }}
      />
    </>
  );
};

export default WebhookForm;
