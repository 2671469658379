import { ModuleWrapper } from "components/fragments";
import React from "react";
import styles from "./app-view.module.scss";
import { useApi, useModal, useMount, useRouter } from "hooks";
import { Path } from "paths";
import Webhooks from "../webhooks/webhooks";
import AppInfo from "./app-info";
import { HeaderB } from "components/headers";
import locale from "translations";
import { getApplicationByID } from "apis/application.api";

const AppView = () => {
  const { query } = useRouter();
  const { id } = query || {};
  const deleteAppModal = useModal();

  const request = useApi({
    api: getApplicationByID,
    params: {
      id,
    },
    handleOwnError: true,
  });

  useMount(() => {
    request.request();
  });

  return (
    <ModuleWrapper
      className={styles.container}
      header={
        <HeaderB
          className="mb-0"
          returnPath={Path.APP}
          returnText={locale.myApps}
          title={request?.result?.displayName}
        />
      }
    >
      <AppInfo request={request} id={id} deleteAppModal={deleteAppModal} />
      <Webhooks id={id} />
    </ModuleWrapper>
  );
};

export default AppView;
