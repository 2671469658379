import React from "react";
import styles from "./select.module.scss";
import classnames from "classnames";
import { Select as AntSelect } from "antd";
import { Icon, Text } from "components/commons";
import { DownOutlined } from "@ant-design/icons";
import { prettifyProductCreationType, prettifyProductType, sortByKeyName } from "services";
import { v4 as uuidv4 } from "uuid";

const { Option } = AntSelect;

const Select = ({
  required,
  error,
  className,
  placeholder,
  options = [],
  value,
  onChange,
  onSearch = false,
  name,
  loading,
  searchable,
  disabled,
  pagination,
  height = "h-field",
  customRenderContent,
  ...props
}) => {
  return (
    <div
      className={classnames(
        "flex items-center border rounded border-solid relative cursor-pointer",
        {
          "border-white-darker text-black": !error,
          "border-red text-red": error,
          "bg-gray-disabled": disabled,
          "bg-white": !pagination,
        },
        className,
        styles.select,
        height
      )}
    >
      <AntSelect
        bordered={false}
        className={classnames(styles.container, "text-sm w-full h-full", {
          "text-gray-500": pagination || disabled,
        })}
        showSearch={searchable}
        dropdownRender={!customRenderContent ? null : customRenderContent}
        placeholder={loading ? "Loading..." : placeholder}
        value={loading && !options.length ? null : value}
        onChange={(v, option) => {
          onChange(name, {
            value: v,
            option,
          });
        }}
        onSearch={onSearch}
        filterOption={(input, option) => {
          return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        suffixIcon={
          <div className="bg-white">
            <DownOutlined />
          </div>
        }
        loading={loading}
        disabled={disabled || (loading && !options.length)}
        {...props}
      >
        {options.sort(sortByKeyName("text")).map((option, i) => {
          return (
            <Option
              key={uuidv4()}
              value={option.value}
              title={option.text}
              className="py-md text-gray"
            >
              {option.custom || option.text}
              {option.product?.creationType ? (
                <Text label>{prettifyProductCreationType(option.product?.creationType)}</Text>
              ) : (
                <Text label>{prettifyProductType(option.product?.type)}</Text>
              )}
            </Option>
          );
        })}
      </AntSelect>
      {required && (
        <div className={classnames(styles.required, { [`${styles.error}`]: error })}>
          <Icon
            name="asterisk"
            className={classnames(styles.icon, { [`${styles.error}`]: error })}
          />
        </div>
      )}
    </div>
  );
};

export default Select;
