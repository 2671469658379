import enumeration from "./enumeration";

const StaffRole = {
  SellOnPos: "SELL_ON_POS",
  TopupCredits: "TOPUP_CREDITS",
  RegisterGuests: "REGISTER_GUESTS",
  Manager: "MANAGER",
};

export default enumeration(StaffRole);
