import React from "react";
import { PageHeader, Container, PageFooter } from "components/commons";
import classnames from "classnames";
import styles from "./layout-b.module.scss";

const LayoutA = ({ children, padding, paddingless, logoRedirectUrl }) => {
  return (
    <div className={classnames("flex flex-col h-full bg-gray-lightest")}>
      <PageHeader logoRedirectUrl={logoRedirectUrl} />
      <Container
        padding={padding}
        mb={"mb-0"}
        paddingless={paddingless}
        className={classnames("flex-grow", styles.layoutAContainer)}
      >
        {children}
      </Container>
      <PageFooter />
    </div>
  );
};

export default LayoutA;
