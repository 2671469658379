import { useMount, useRouter } from "hooks";
import { Path } from "paths";
import React from "react";
import { Route } from "react-router-dom";
import { redirectTo } from "services";

const AuthorizedRoute = ({ component: Component, ...rest }) => {
  const { location } = useRouter();

  useMount(() => {
    const currentLocation = location.pathname;
    if (!localStorage.getItem("accessToken") && currentLocation !== "/") {
      redirectTo(`${Path.AUTH}?redirectUrl=${currentLocation}`);
      // history.push(Path.AUTH, { redirectUrl: currentLocation });
    }
  });

  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
};

export default AuthorizedRoute;
