import React from "react";
import styles from "./choose-avatar.module.scss";
import { Preview, Icon } from "components/commons";
import classnames from "classnames";
// import UploadImage from "./upload-image";
// import { logoIcons } from "services/text.service";

const UploadAvatar = ({
  iconModal,
  image = null,
  onChange,
  title,
  name,
  className,
  onClick,
  ...preview
}) => {
  return (
    <div
      className={classnames(className, styles.avatarContainer, "flex items-center py-sm")}
      onClick={onClick}
    >
      <div className="text-center">
        {/* {image && (
          <Icon
            name="remove"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onChange({
                image: "",
              });
            }}
            color="text-white"
            fontSize="10px"
            className={classnames(styles.removeImage, "bg-red-lightest")}
          />
        )} */}
        <Preview {...preview} className="m-auto" image={image} title={title} />

        <div className={styles.pencilContainer}>
          <Icon color="text-pelorous" className={styles.pencil} name="pencil" />
        </div>
      </div>
      {/* {active && (
        <div
          className={styles.dimmer}
          onClick={() => {
            setActive(false);
          }}
        ></div>
      )} */}
    </div>
  );
};

export default UploadAvatar;
