import { Popover } from "antd";
import React from "react";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import styles from "./option-menu.module.scss";
import { Icon } from "..";

const OptionMenu = ({ options, title, className, loading }) => {
  return (
    <Popover
      overlayClassName="options-popover"
      placement="bottomRight"
      title={title}
      content={options.map((option) => {
        return (
          <button
            key={uuidv4()}
            className={classNames(`border-none outline-none w-full text-left`, option?.className)}
            onClick={() => {
              option.onClick();
            }}
          >
            {option.text}
          </button>
        );
      })}
      trigger="click"
      arrow={false}
    >
      <div className={classNames("text-right", className)}>
        {loading ? (
          <Icon loading className="mr-md" />
        ) : (
          <i
            className={classNames(`icon-options align-middle button`, styles.button)}
            style={{
              fontSize: 3,
            }}
          />
        )}
      </div>
    </Popover>
  );
};

export default OptionMenu;
