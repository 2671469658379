const PillType = {
  Default: "DEFAULT",
  Secondary: "SECONDARY",
  Tertiary: "TERTIARY",
  Blueish: "BLUEISH",
  Green: "GREEN",
  Orange: "ORANGE",
  Red: "RED",
  Black: "BLACK",
  Disabled: "DISABLED",
  Italic: "ITALIC",
  Blue: "BLUE",
  Gray: "GRAY",
  Greenish: "GREENISH",
  Yellow: "YELLOW",
  Reddish: "REDDISH",
  Opaque: "OPAQUE",
};

export default Object.freeze(PillType);
