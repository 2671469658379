import React from "react";
import lang from "translations";
import { useApi } from "hooks";
import { createApplication } from "apis/application.api";
import AppForm from "../app-form/app-form";
import { uploadApplicationLogo } from "apis/storage.api";

const CreateAppModal = ({ active, close, refreshList }) => {
  const { request, loading } = useApi({
    api: createApplication,
  });
  const { request: uploadLogo } = useApi({
    api: uploadApplicationLogo,
  });
  return (
    <AppForm
      close={close}
      active={active}
      title={lang.createNewApp}
      loading={loading}
      callback={refreshList}
      request={async (params) => {
        if (params.logoFile) {
          params.logoLink = null;
        }
        const res = await request({ ...params });
        if (res && params.logoFile) {
          await uploadLogo({ id: res.data?.id, file: params.logoFile });
        }
        return {
          message: "Created successfully!",
        };
      }}
    />
  );
};

export default CreateAppModal;
