import field from "enums/field";
import Validation from "services/validation.service";

const initialFormState = (initialState = {}) => {
  const {
    id = null,
    name = "",
    url = "",
    events = [],
    authUsername = null,
    authPassword = null,
    active = false,
  } = initialState;

  return {
    webhookId: {
      name: "webhookId",
      value: id,
      type: field.ANY,
    },
    name: {
      name: "name",
      value: name,
      type: field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
      label: "Name",
    },
    url: {
      name: "url",
      value: url,
      type: field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 255,
      label: "Target URL",
    },
    status: {
      name: "status",
      value: active,
      type: field.ANY,
      label: "Status",
    },
    authentication: {
      name: "authentication",
      value: Boolean(authUsername || authPassword),
      type: field.CHECKBOX,
      label: "Authentication",
    },
    authUsername: {
      name: "authUsername",
      value: authUsername,
      type: field.INPUT,
      maxLength: 255,
      label: "Username",
    },
    authPassword: {
      name: "authPassword",
      value: authPassword,
      type: field.INPUT,
      maxLength: 75,
      label: "Password",
    },
    events: {
      name: "events",
      value: events,
      type: field.ANY,
      maxLength: 75,
      label: "Select Triggers:",
    },
  };
};

export default initialFormState;
