import {
  Button,
  Dropdown,
  Field,
  Icon,
  Input,
  Panel,
  Pill,
  Text,
  Title,
  Toast,
  Tooltip,
} from "components/commons";
import { useModal, useRouter } from "hooks";
import React from "react";
import locale from "translations";
import classNames from "classnames";
import styles from "./app-view.module.scss";
// import { Path } from "paths";
import { v4 as uuidv4 } from "uuid";
import EditApp from "../edit-app/edit-app";
import DeleteApp from "../delete-app/delete-app";
import { FragmentA, ModuleWrapper } from "components/fragments";
import { ApprovalStatus, PillType, StyleType } from "enums";
import { Path } from "paths";
import { prettifyApprovalStatus } from "services";

const AppInfo = ({ id, isScrolledDown, request: getApp }) => {
  const { history } = useRouter();
  const editAppModal = useModal();
  const deleteAppModal = useModal();

  const { request, loading, result = {}, error } = getApp;

  const {
    displayName: title,
    colors = ",",
    description,
    logoLink: image,
    clientSecret,
    clientId,
    approvalStatus,
    category,
    callbackUrl,
  } = result?.data || {};

  // const [backgroundColor, fontColor] = colors.toString().split(",");

  return (
    <ModuleWrapper paddingless error={error} header={<div id="Code"></div>}>
      <div
        className={classNames(styles.header, "mb-lg w-full", {
          [`${styles.headerAttached}`]: isScrolledDown,
        })}
      >
        {/* <Preview
          title={title}
          color={backgroundColor || "#ffffff"}
          fontColor={fontColor || "#46B0C6"}
          image={image}
          description={description}
        /> */}
        <div className={"w-full"}>
          <div className="flex justify-between">
            <div className="flex flex-wrap items-center gap-2">
              <Title lg loading={loading}>
                {title}
              </Title>
              <Pill
                type={approvalStatus === ApprovalStatus.Enabled ? PillType.Green : PillType.Yellow}
              >
                {prettifyApprovalStatus(approvalStatus)}
              </Pill>
            </div>
            <Dropdown
              options={[
                {
                  text: "Edit",
                  onClick: () => {
                    editAppModal.show();
                  },
                },
                {
                  text: "Delete",
                  className: "text-red",
                  onClick: () => {
                    deleteAppModal.show({
                      title: locale.populate(locale.askDeleteName, [title]),
                      id,
                      callback: () => {
                        history.push(Path.APPS);
                      },
                    });
                  },
                },
              ]}
            >
              <div className="text-pelorous text-sm flex items-center font-bold">
                Actions <Icon loading={loading} name={"down-caret"} />
              </div>
            </Dropdown>
            {/* <div className={styles.actions}>
              <PillButton
                style={StyleType.Success}
                icon="pencil"
                className={"mr-xs"}
                onClick={() => {
                  editAppModal.show();
                }}
              >
                {locale.edit}
              </PillButton>
              <PillButton
                style={StyleType.Danger}
                icon="bin"
                className={"mr-xs"}
                onClick={() => {
                  deleteAppModal.show({
                    title: locale.populate(locale.askDeleteName, [title]),
                    id,
                    callback: () => {
                      history.push(Path.APPS);
                    },
                  });
                }}
              >
                {locale.delete}
              </PillButton>
            </div> */}
          </div>
          <Text description className="pt-2 md:pt-0" loading={loading}>
            {description}
          </Text>
        </div>
      </div>
      <FragmentA title={locale.credentials}>
        <Panel>
          <div id="Code">
            <div className="flex justify-between">
              <div>
                <Title sm className="mb-md pr-2 md:pr-0">
                  {locale.credentials}
                </Title>
                <Text description className="mb-md pr-2">
                  Pouch Connect API implements OAuth 2. Below are the Client ID and Client Secret
                  that will allow your application to be authorized to make request.
                </Text>
              </div>
              <div>
                <Button type={StyleType.Secondary} customPadding="py-xs px-sm">
                  <Tooltip title={locale.copyAllAuthenticationAsJSONObj}>
                    <div
                      className="flex items-center"
                      onClick={async () => {
                        if (!loading) {
                          await navigator.clipboard.writeText(
                            JSON.stringify({
                              clientId,
                              clientSecret,
                            })
                          );
                          Toast({
                            content: locale.populate(locale.nCopiedInClipboard, ["Object"]),
                            success: true,
                            icon: "check",
                          }).open();
                        }
                      }}
                    >
                      <span className="mr-xs">
                        <Icon
                          name="copy1"
                          fontSize="25px"
                          loading={loading}
                          className={styles.copy}
                        />
                      </span>
                      <Text color="text-pelorous" size="text-xs">
                        Copy Credentials
                      </Text>
                    </div>
                  </Tooltip>
                </Button>
              </div>
            </div>

            <div className={classNames(styles.code, "p-md")}>
              {[
                [locale.clientID, clientId],
                [locale.clientSecret, clientSecret],
              ].map((item) => {
                return (
                  <Field label={item[0]} key={uuidv4()}>
                    <Input
                      readOnly
                      value={item[1]}
                      className={styles.input}
                      placeholder="Loading..."
                      iconSuffix={
                        <Icon
                          name="copy1"
                          fontSize="25px"
                          className={styles.copy}
                          loading={loading}
                          onClick={async () => {
                            if (!loading) {
                              await navigator.clipboard.writeText(item[1]);
                              Toast({
                                content: locale.populate(locale.nCopiedInClipboard, [item[0]]),
                                success: true,
                                icon: "check",
                              }).open();
                            }
                          }}
                        />
                      }
                    />
                  </Field>
                );
              })}
            </div>
          </div>
        </Panel>
      </FragmentA>

      <EditApp
        title={"Edit App"}
        callback={() => {
          request();
        }}
        {...editAppModal}
        initialState={{
          displayName: title,
          description,
          preview: {
            image,
          },
          colors,
          category,
          callbackUrl,
        }}
        id={id}
      />
      <DeleteApp {...deleteAppModal} />
    </ModuleWrapper>
  );
};

export default AppInfo;
