import React from "react";
import { Modal, Icon, Text, SelectColor, Field } from "..";
import UploadImage from "../choose-avatar/upload-image";
import { logoIcons } from "services/text.service";
import styles from "./choose-icon.module.scss";
import classnames from "classnames";

const ChooseIcon = ({ colors, onChangeColors, image, onChange, name, ...modal }) => {
  return (
    <Modal {...modal}>
      <div className={classnames(styles.container)}>
        <div className={classnames(styles.header, "p-md")}>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Icon
                name="left-caret"
                color="text-gray"
                fontSize="20px"
                onClick={() => {
                  modal.close();
                }}
              />
              <Text size="text-lg" fontWeight="font-bold">
                App Icon
              </Text>
            </div>
            <Icon
              name="remove"
              color="text-gray"
              fontSize="20px"
              onClick={() => {
                modal.close();
              }}
            />
          </div>
        </div>
        <div className={"grid grid-cols-6 p-md"}>
          {logoIcons.map((icon) => {
            return (
              <div
                className={classnames(styles.select, "m-sm flex items-center cursor-pointer", {
                  "text-gray": image !== icon,
                  [`${classnames(styles.selectedIcon)}`]: image === icon,
                })}
                onClick={() => {
                  onChange({
                    value: icon,
                  });
                }}
              >
                <Icon
                  name={icon}
                  className={classnames("m-auto", {
                    "text-gray": image !== icon,
                    "text-pelorous-darker": image === icon,
                  })}
                  fontSize="25px"
                />
              </div>
            );
          })}
          <UploadImage
            value={image}
            name={name}
            onChange={async (val) => {
              await onChange(val);
              modal.close();
            }}
          />
        </div>
        <div className="m-md w-1/4">
          <Field label={"Icon Color"}>
            <SelectColor
              value={colors}
              setValue={(color) => {
                onChangeColors(color);
              }}
            />
          </Field>
        </div>
      </div>
    </Modal>
  );
};

export default ChooseIcon;
