import field from "enums/field";
import Validation from "services/validation.service";

const initialFormState = (initialState = {}) => {
  const {
    displayName = "",
    description = "",
    category = null,
    preview = { image: null, text: "Upload Logo" },
    colors = "#7a7984,",
    callbackUrl = "",
  } = initialState;
  return {
    displayName: {
      name: "displayName",
      value: displayName,
      type: field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
      label: "App Display Name",
    },
    description: {
      name: "description",
      value: description,
      type: field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 255,
      label: "Description",
    },
    category: {
      name: "category",
      value: category,
      type: field.DROPDOWN,
      validations: [Validation.required()],
      required: true,
      label: "Category",
      placeholder: "Select a category",
      options: [
        {
          text: "Accounting Software",
          value: "ACCOUNTING_SOFTWARE",
        },
        {
          text: "Distribution",
          value: "DISTRIBUTION",
        },
        {
          text: "Inventory Management",
          value: "INVENTORY_MANAGEMENT",
        },
        {
          text: "Point-of Sale",
          value: "POINT_OF_SALE",
        },
        {
          text: "Property Management System",
          value: "PROPERTY_MANAGEMENT_SYSTEM",
        },
        {
          text: "Others",
          value: "OTHERS",
        },
      ],
    },
    preview: {
      name: "preview",
      label: "Logo",
      type: field.ANY,
      required: true,
      error: false,
      ...preview,
    },
    colors: {
      name: "colors",
      value: colors,
      type: field.ANY,
      label: "Select theme",
    },
    callbackUrl: {
      name: "callbackUrl",
      value: callbackUrl,
      type: field.INPUT,
      label: "Callback URL",
    },
  };
};

export default initialFormState;
