export const redirectTo = (url) => {
  window.location.href = url;
};

export const reloadPage = () => {
  window.location.reload();
};

export const addQueryParam = (path, obj) => {
  let url = "";
  Object.keys(obj).forEach((key) => {
    const param = `${key}=${obj[key]}`;
    url = url ? `${url}&${param}` : param;
  });
  return `${path}?${url}`;
};
