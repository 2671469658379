import React from "react";
import { Route } from "react-router-dom";
import { Path } from "paths";
import { redirectTo } from "services";

const AuthRoute = ({ component: Component, ...rest }) => {
  if (localStorage.getItem("accessToken")) {
    redirectTo(Path.SLASH);
  }

  return (
    <div>
      <Route
        {...rest}
        render={(props) => {
          return <Component {...props} />;
        }}
      />
    </div>
  );
};

export default AuthRoute;
