import React, { useContext, useEffect, useMemo } from "react";
import {
  Modal,
  ActionButton,
  Form,
  Field,
  Input,
  TextArea,
  Toast,
  ChooseAvatar,
  Select,
} from "components/commons";
import lang from "translations";
import classnames from "classnames";
import styles from "./style.module.scss";
import { useForm, useModal } from "hooks";
import { useCallback } from "react";
import initialFormState from "./app-state";
import { AppContext } from "contexts";
import ChooseIcon from "components/commons/choose-icon/choose-icon";

const AppForm = ({
  request,
  loading,
  active,
  close,
  initialState,
  callback = undefined,
  title,
  isEdit = false,
}) => {
  const { globalErrorModal } = useContext(AppContext);
  const iconModal = useModal();

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const {
    fields,
    modifyField,
    submitForm,
    getFormValues,
    clearForm,
    // applyFieldErrors,
    // validateForm,
    dirty,
  } = useForm({
    initialState: formState,
  });

  const handleSubmit = useCallback(async () => {
    try {
      const params = getFormValues();
      params.logoLink = fields.preview.image;
      params.logoFile = fields.preview.file;

      const res = await request({ ...params });
      clearForm();
      close();
      Toast({
        content: res.message,
        success: true,
        icon: "check",
      }).open();
      if (callback) {
        callback();
      }
    } catch ({ code, handleError }) {
      if (handleError) {
        handleError();
      }
    }
  }, [getFormValues, request, clearForm, close, callback, fields]);

  useEffect(() => {
    if (active) {
      clearForm();
    }
    // eslint-disable-next-line
  }, [active]);

  const [background, fontColor] = fields.colors.value.split(",");

  return (
    <>
      <Modal
        active={globalErrorModal.active || iconModal.active ? false : active}
        className={classnames(styles.modal)}
        title={title}
        onClose={close}
        close={close}
        actionContent={
          <ActionButton
            loading={loading}
            className="px-md py-md"
            secondary={{
              text: lang.cancel,
              onClick: () => {
                clearForm();
                close();
              },
            }}
            primary={{
              text: !isEdit ? lang.createApp : lang.save,
              loading,
              disabled: !dirty || !fields.preview.image,
              onClick: () => {
                submitForm(handleSubmit);
              },
            }}
          />
        }
      >
        <Form onSubmit={handleSubmit}>
          <div className="text-center">
            <ChooseAvatar
              onClick={() => {
                iconModal.show();
              }}
              className="m-auto"
              {...fields.preview}
              color={background}
              fontColor={fontColor}
              title={fields.displayName.value}
              onChange={({ value }) => {
                modifyField("preview", {
                  image: value,
                });
              }}
            />
          </div>
          <Field {...fields.displayName}>
            <Input
              required
              {...fields.displayName}
              className={classnames("rounded")}
              placeholder="My First App"
              onChange={modifyField}
            />
          </Field>
          <Field {...fields.description}>
            <TextArea
              required
              {...fields.description}
              placeholder="This is my first app."
              onChange={modifyField}
            />
          </Field>
          <Field {...fields.category}>
            <Select required {...fields.category} onChange={modifyField} />
          </Field>
          <Field {...fields.callbackUrl}>
            <Input
              {...fields.callbackUrl}
              className={classnames("rounded")}
              onChange={modifyField}
            />
          </Field>
        </Form>
      </Modal>
      <ChooseIcon
        image={fields.preview.image}
        colors={fields.colors.value}
        onChangeColors={(color) => {
          modifyField("colors", {
            value: color,
          });
        }}
        {...iconModal}
        onChange={({ value, file }) => {
          modifyField("preview", {
            image: value,
            file: file,
          });
        }}
        {...fields.preview}
      />
    </>
  );
};

export default AppForm;
