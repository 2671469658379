import lang from "translations";
import React, { useContext } from "react";
import { Container, Title, Text, Image } from "..";
// import { OctopusRain } from "images";
import { AppContext } from "contexts";
import classnames from "classnames";

const PageError = ({ padding }) => {
  const { pageErrorImage } = useContext(AppContext);
  return (
    <Container
      className={classnames("flex bg-white p-sm ", {
        padding,
        "md:p-xxl": !padding,
      })}
    >
      <div className="md:flex m-auto">
        <div className="w-1/4">
          <Image className="m-md md:m-auto w-full" src={pageErrorImage} />
        </div>
        <div
          className="md:flex items-center w-3/4"
          style={{
            maxWidth: "400px",
          }}
        >
          <div className="m-auto">
            <Title xl>Ugh.</Title>
            <Text color="text-gray" size="text-md">
              {lang.eitherYouHavePoorConnection}
            </Text>
            <Text color="text-gray" size="text-md">
              {lang.somethingWentWrong}
            </Text>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default PageError;
