import React from "react";
import { Modal, ActionButton, Text } from "components/commons";
import lang from "translations";
import classnames from "classnames";
import styles from "./style.module.scss";

const SomethingWentWrongModal = ({ active, close }) => {
  return (
    <Modal
      active={active}
      className={classnames(styles.modal)}
      title={lang.somethingWentWrong}
      onClose={close}
      close={close}
      actionContent={
        <ActionButton
          className="px-md py-md"
          secondary={null}
          primary={{
            text: lang.ok,
            onClick: () => {
              close();
            },
          }}
        />
      }
    >
      <Text>{lang.looksLikeYouEncounteredSomething}</Text>
    </Modal>
  );
};

export default SomethingWentWrongModal;
