import React from "react";
import { Skeleton as Loader } from "antd";
import styles from "./skeleton.module.scss";
import classNames from "classnames";

const Skeleton = ({ single, double, className, circle }) => {
  if (single) {
    return (
      <Loader.Button active={true} size={"small"} shape={"square"} className={styles.container} />
    );
  }

  if (double) {
    return (
      <div>
        <Loader.Button active={true} size={"small"} shape={"square"} className={styles.small} />
        <Loader.Button active={true} size={"small"} shape={"square"} className={styles.medium} />
      </div>
    );
  }

  if (circle) {
    return <Loader.Avatar active />;
  }
  return <Loader width="100%" active={true} className={classNames(className, styles.content)} />;
};

export default Skeleton;
