import React, { useCallback, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useMount } from "hooks";
import { ProfileContext } from "contexts";
// import { getProfile } from "apis";
import profileState from "./profile.state";
import { LayoutA } from "components/layouts";
import { AccountSetting } from "modules";
import { Path } from "paths";
import AppPage from "pages/app/app.page";
import IntegrationPage from "pages/integration/integration.page";
import jwt_decode from "jwt-decode";

const AuthorizedPage = () => {
  const [profile, setProfile] = useState(profileState);

  // const { request: getProfileRequest, loading: fetchingProfile = true } = useApi({
  //   api: getProfile,
  // });

  useMount(() => {
    fetchProfile();
  });

  const fetchProfile = useCallback(async () => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      const info = await jwt_decode(userInfo);
      setProfile(info);
    }
  }, [setProfile]);

  return (
    <ProfileContext.Provider value={{ profile, fetchProfile, loading: false }}>
      <LayoutA paddingless={true}>
        <Switch>
          <Route path={Path.APP} component={AppPage} />
          <Route path={Path.INTEGRATIONS} component={IntegrationPage} />
          <Route path={Path.ACCOUNT_SETTING} component={AccountSetting} />
          <Redirect to={Path.HOME} />
        </Switch>
      </LayoutA>
    </ProfileContext.Provider>
  );
};

export default AuthorizedPage;
