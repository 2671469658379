import React, { useCallback } from "react";
import classnames from "classnames";
import styles from "./text-area.module.scss";
import { Icon } from "..";

const TextArea = ({
  name,
  error,
  maxLength,
  value,
  placeholder,
  className,
  required,
  disabled = false,
  onChange,
}) => {
  const onChangeCb = useCallback(
    (e) => {
      if (onChange) {
        onChange(name, { value: e?.target.value });
      }
    },
    [onChange, name]
  );

  return (
    <div
      className={classnames(
        "pn-input flex items-center bg-white border rounded border-solid relative min-h-field",
        {
          "border-white-darker text-black bg-white": !error,
          "border-red text-red": error,
          "bg-gray-disabled": disabled,
        },
        styles.container,
        className
      )}
    >
      <textarea
        value={value}
        maxLength={maxLength}
        onChange={onChangeCb}
        placeholder={placeholder}
        className={classnames(
          "w-full px-md py-md focus:outline-none text-sm text-black",
          {
            "border-white-darker": !error,
            "border-red text-red": error,
          },
          className
        )}
      ></textarea>
      {required && (
        <div className={classnames(styles.required, { [`${styles.error}`]: error })}>
          <Icon
            name="asterisk"
            className={classnames(styles.icon, { [`${styles.error}`]: error })}
          />
        </div>
      )}
    </div>
  );
};

export default TextArea;
