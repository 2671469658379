import React, { useCallback } from "react";
import { Pagination as AntPagination } from "antd";
import { Select } from "..";
import classnames from "classnames";

const Pagination = ({
  className,
  page = 1,
  pageSize = 10,
  total = 50,
  onChangePage,
  onChangePageSize,
  onChangePagination,
}) => {
  const onChangePageCb = useCallback(
    (page) => {
      if (onChangePage) {
        onChangePage(page);
      }
      if (onChangePagination) {
        console.log("PAGE", page);
        onChangePagination({
          page,
          pageSize,
        });
      }
    },
    [onChangePage, onChangePagination, pageSize]
  );

  const onChangePageSizeCb = useCallback(
    (name, obj) => {
      if (onChangePageSize) {
        onChangePageSize(obj.value);
      }
      if (onChangePagination) {
        onChangePagination({
          page: 1,
          pageSize: obj.value,
        });
      }
    },
    [onChangePageSize, onChangePagination]
  );

  const placeholder = "20 per page";
  const pageSizeOptions = [
    { text: "10 per page", value: 10 },
    { text: "20 per page", value: 20 },
    { text: "30 per page", value: 30 },
    { text: "40 per page", value: 40 },
    { text: "50 per page", value: 50 },
  ];

  return (
    <div className={classnames(className)}>
      <div className="block md:flex items-center justify-end">
        {total >= pageSize && (
          <AntPagination
            className="text-right bg-none"
            current={page}
            pageSize={pageSize}
            total={total}
            onChange={onChangePageCb}
            showSizeChanger={false}
          />
        )}

        {total > 10 ? (
          <Select
            className="w-36 max-h-8 my-md md:ml-sm ml-auto bg-none"
            name="pagination"
            placeholder={placeholder}
            options={pageSizeOptions}
            value={pageSize}
            onChange={onChangePageSizeCb}
            pagination={true}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Pagination;
