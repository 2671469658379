import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AppContext } from "contexts";
import { useState } from "react";
import { Path } from "paths";
import { useModal } from "hooks";
import { LogoutSessionModal, SomethingWentWrongModal } from "components/modals";
import { OctopusHiding, OctopusRain } from "images";
import { Image, Loader, Text, Title } from "components/commons";
import { LayoutA } from "components/layouts";
import locale from "translations";
import { AuthorizedPage, AuthPage } from "pages";
import { AuthorizedRoute, AuthRoute } from "routes";
import HomePage from "pages/home/home.page";

function App() {
  const [appState, setAppState] = useState({ error: false });
  const logOutSessionModal = useModal();
  const globalErrorModal = useModal();
  const [globalLoading, setGlobalLoading] = useState(false);
  const [error, setError] = useState(false);

  return (
    <AppContext.Provider
      value={{
        appState,
        setAppState,
        logOutSessionModal,
        globalErrorModal,
        pageErrorImage: OctopusRain,
        setGlobalLoading,
        setGlobalError: setError,
      }}
    >
      <div className="App">
        {error ? (
          <LayoutA hasUserNav={false}>
            <div className="text-center">
              <Image className="m-auto" src={OctopusHiding} />
              <Title className="mt-md" xl color="text-blue">
                {locale.somethingWentWrong}
              </Title>
              <Text> {locale.looksLikeYouEncounteredSomething}</Text>
            </div>
          </LayoutA>
        ) : (
          <Router>
            <Switch>
              <AuthRoute exact path={Path.AUTH} component={AuthPage} />
              <Route exact path={Path.HOME} component={HomePage} />
              <AuthorizedRoute path={Path.SLASH} component={AuthorizedPage} />
            </Switch>
          </Router>
        )}
      </div>
      <LogoutSessionModal {...logOutSessionModal} />
      <SomethingWentWrongModal {...globalErrorModal} />
      <div className="hidden">
        <Image src={OctopusRain} />
      </div>
      <Loader loading={globalLoading} />
    </AppContext.Provider>
  );
}

export default App;
