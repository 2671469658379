import React from "react";
import { Route, Switch } from "react-router-dom";
import { Path } from "paths";
import { useMount } from "hooks";
import { setPageTitle } from "services";
import AppList from "modules/app/app-list/app-list.module";
import AppView from "modules/app/app-view/app-view";

const AppPage = () => {
  useMount(() => {
    setPageTitle("myApps");
  });

  return (
    <div>
      <Switch>
        <Route exact path={Path.APP} component={AppList} />
        <Route exact path={Path.APP_ID} component={AppView} />
      </Switch>
    </div>
  );
};

export default AppPage;
