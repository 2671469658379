export const profileRequest = {
  venueId: {
    key: "venueId",
  },
  profileId: {
    key: "profileId",
  },
  firstName: {
    key: "firstName",
  },
  lastName: {
    key: "lastName",
  },
};

export const profileCreateRequest = {
  email: {
    key: "email",
  },
  password: {
    key: "password",
  },
  profile: {
    transform: ({ src }) => {
      return { firstName: src.firstName, lastName: src.lastName };
    },
  },
};
