import { Post, Upload } from "services";
import { v1 as uuidv1 } from "uuid";
import { ApiPath } from "paths";

export const uploadFile = async ({ file }) => {
  const formData = new FormData();
  const fileName = uuidv1();
  formData.append("files", file, fileName);
  const res = await Upload(`${ApiPath.STORAGE_UPLOAD}`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};

export const uploadApplicationLogo = async ({ id, file }) => {
  const formData = new FormData();
  const fileName = uuidv1();
  formData.append("file", file, fileName);
  const res = await Post(`${ApiPath.LOGO_UPLOAD(id)}`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res;
};
