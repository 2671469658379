import { Delete, Get, Post, Put } from "services";

export const getWebhooks = async ({ id }) => {
  const res = await Get(`applications/${id}/webhooks`);
  return res;
};

export const deleteWebhook = async ({ id }) => {
  const res = await Delete(`webhooks/${id}`);
  return res;
};

export const createWebhook = async ({ id, ...body }) => {
  const res = await Post(`applications/${id}/webhooks`, body);
  return res;
};

export const updateWebhook = async (body) => {
  const res = await Put(`webhooks/${body.id}`, body);
  return res;
};
