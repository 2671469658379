import React, { memo } from "react";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import styles from "./simple-table.module.scss";

export const SimpleTable = ({
  addBorder,
  data,
  columns,
  className,
  showHeader = true,
  content,
  useKeys,
  breakAll = false,
  onRowClick,
}) => {
  return (
    <div className={className}>
      <table className={classnames("table-fixed table w-full")}>
        {showHeader && (
          <thead>
            <tr>
              {columns.map((c) => {
                return (
                  <th
                    key={uuidv4()}
                    className={classnames(
                      "text-xs font-bold text-gray align-bottom py-sm",
                      c.width,
                      c.headerClassName,
                      {
                        "border-solid border-b border-gray-lightest": addBorder,
                      }
                    )}
                  >
                    {c.text}
                  </th>
                );
              })}
            </tr>
          </thead>
        )}

        {content || (
          <tbody>
            {data.map((row, rowIndex) => {
              return (
                <tr
                  key={useKeys ? `${useKeys}-row-${rowIndex}` : uuidv4()}
                  className={classnames(styles.row, {
                    [`${styles.removeHighlightOnHover}`]: row._highlightRemove,
                    "cursor-pointer": onRowClick,
                  })}
                >
                  {columns.map((c, columnIndex) => {
                    return (
                      <td
                        key={useKeys ? `${useKeys}-column-${rowIndex}${columnIndex}` : uuidv4()}
                        className={classnames("text-sm align-top break-word", c.className, {
                          "border-solid border-b border-gray-lightest":
                            addBorder && rowIndex + 1 !== data.length,
                          "break-all": breakAll,
                        })}
                        onClick={() => {
                          if (onRowClick && !c.unclickable) {
                            onRowClick({
                              index: rowIndex,
                              data: row,
                            });
                          }
                        }}
                      >
                        {row[c.key]}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default memo(SimpleTable);
