import React from "react";
import { useRouter } from "hooks";
import { Icon } from "components/commons";
import classnames from "classnames";
import styles from "./button.module.scss";
const ButtonLink = ({
  className,
  children,
  path,
  onClick,
  icon,
  suffix = false,
  prefix = !suffix,
  loading,
  newTabPath,
  flex = true,
  iconClassName = "text-xs",
  size = "text-sm",
  anchor,
  disabled,
  colored = true,
}) => {
  const { history } = useRouter();

  const renderIcon = icon ? <Icon loading={loading} name={icon} className={iconClassName} /> : null;

  const content = (
    <div className="items-center flex">
      {prefix && renderIcon}
      <div className={classnames("m-auto font-bold", size ? size : "text-md m-auto")}>
        {" "}
        {children}
      </div>
      {suffix && renderIcon}
    </div>
  );
  if (anchor) {
    return (
      <a
        className={classnames("flex items-center", size ? size : "text-md m-auto", {
          "text-pelorous": colored,
        })}
        rel="noreferrer"
        href={newTabPath}
        target="_blank"
      >
        {content}
      </a>
    );
  }

  return (
    <button
      type="button"
      className={classnames("cursor-pointer inline-block", className, size, styles.buttonLink, {
        "flex items-center": flex,
        "text-gray cursor-not-allowed": disabled,
        "text-pelorous": !disabled && colored,
      })}
      onClick={() => {
        if (disabled) {
          return false;
        }
        if (newTabPath) {
          const win = window.open(newTabPath, "_blank");
          win.focus();
        } else {
          if (onClick) {
            onClick();
            return;
          }
          history.push(path);
        }
      }}
    >
      {content}
    </button>
  );
};

export default ButtonLink;
