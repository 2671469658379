import React from "react";
import { Icon } from "..";
import styles from "./loader.module.scss";

const Loader = ({ loading }) => {
  if (!loading) {
    return null;
  }
  return (
    <div className={styles.container}>
      <Icon loading className="m-auto text-white" fontSize="90px" color="text-black" />
    </div>
  );
};

export default Loader;
