import React, { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { Pagination } from "..";
import Card from "../card/card";
import styles from "./card-list.module.scss";
import classNames from "classnames";
import locale from "translations";

const CardList = ({ loading, items = [], actions = [], total, setPagination, pagination }) => {
  // const [layout, setLayout] = useState("grid");

  const renderItems = useMemo(() => {
    if (loading) {
      return [0, 1, 2, 4, 5];
    }
    return items;
  }, [loading, items]);

  return (
    <div>
      <div className="flex items-center justify-end md:justify-between mb-md">
        {/* <div className="hidden md:flex items-center">
          {[
            {
              icon: "grid",
              value: "grid",
            },
            {
              icon: "list",
              value: "list",
            },
          ].map((item) => {
            return (
              <div
                key={uuidv4()}
                className={classNames(
                  "mr-xs cursor-pointer",
                  {
                    [`${styles.selected}`]: layout === item.value,
                  },
                  styles.layout
                )}
                onClick={() => {
                  setLayout(item.value);
                }}
              >
                <Icon name={item.icon} color="text-gray" />
              </div>
            );
          })}
        </div> */}
        <Pagination total={total} {...pagination} onChangePagination={setPagination} />
      </div>
      {!renderItems.length && !loading ? (
        <div className={styles.placeholder}>
          <p>{locale.noResultsFound}</p>
        </div>
      ) : (
        <div className={classNames("grid grid-cols-1 md:grid-cols-3 gap-4")}>
          {renderItems.map((item) => {
            return (
              <div key={uuidv4()}>
                <Card
                  loading={loading}
                  {...item}
                  actions={actions.map((a) => {
                    return {
                      ...a,
                      onClick: () => {
                        a.onClick(item);
                      },
                    };
                  })}
                  layout={"grid"}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CardList;
