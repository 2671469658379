import { ModuleWrapper } from "components/fragments";
import { HeaderA } from "components/headers";
import lang from "translations";
import React, { useCallback } from "react";
import { Preview, Text, Title } from "components/commons";
// import { Path } from "paths";
import { useApi, useFilter, useMount } from "hooks";
import { getIntegrations } from "apis/integration.api";
import { Skeleton } from "antd";

const IntegrationList = () => {
  // const { history } = useRouter();
  const {
    request,
    result = { data: {} },
    loading,
  } = useApi({
    api: getIntegrations,
  });

  const { content = [] } = result.data;
  const { filterState } = useFilter({
    page: 0,
    size: 10,
  });

  useMount(() => {
    fetchIntegrations();
  });

  const fetchIntegrations = useCallback(async () => {
    await request(filterState);
  }, [request, filterState]);

  return loading ? (
    <Skeleton title={lang.integrations} />
  ) : (
    <ModuleWrapper>
      <HeaderA title={lang.integrations} description={lang.descriptionPlaceholder}></HeaderA>
      <div className="flex items-left">
        <div className="w-full mt-md grid grid-cols-auto sm:grid-cols-2 xl:grid-cols-3 gap-3">
          {content.map((d, index) => {
            return (
              <>
                <div
                  className="mb-sm bg-white border-l-8 border-pelorous-dark p-md flex items-top 
                             cursor-pointer rounded-lg shadow-lg"
                  key={index}
                >
                  <div
                    className="flex items-top hover:opacity-80"
                    // onClick={() => {
                    //   history.push(Path.INTEGRATION_VIEW_ID(content[index]?.id));
                    // }}
                  >
                    <div>
                      <Preview title={d.displayName} image={d.logoLink} />
                    </div>
                    <div className="mx-lg">
                      <Title>{d.displayName}</Title>
                      <Text className="text-gray-dark my-sm">
                        {d.description || lang.descriptionPlaceholder}
                      </Text>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default IntegrationList;
