import React from "react";
import { Modal, ActionButton, Text } from "components/commons";
import lang from "translations";
import { Path } from "paths";
import { redirectTo } from "services";
// import { useRouter } from "hooks";

const LogoutSessionModal = ({ active, close }) => {
  // const { history, location } = useRouter();
  return (
    <Modal
      active={active}
      noCloseButton
      close={close}
      title={lang.loginSessionEnded}
      actionContent={
        <ActionButton
          className="px-md py-md"
          secondary={null}
          primary={{
            text: lang.ok,
            onClick: () => {
              localStorage.clear();
              // const currentLocation = location.pathname;
              // if (currentLocation !== "/") {
              redirectTo(`${Path.AUTH}?pathname=${window.location.pathname}`);
              // history.push(Path.AUTH, { redirectUrl: currentLocation });
              // }
              close();
            },
          }}
        />
      }
    >
      <Text>{lang.loginHasEnded}</Text>
    </Modal>
  );
};

export default LogoutSessionModal;
