import React, { useCallback, useState } from "react";
import lang from "translations";
import styles from "./choose-avatar.module.scss";
import { Upload, Toast, Icon, Loader, Tooltip } from "components/commons";
import classnames from "classnames";
// import { useApi } from "hooks";
// import { uploadFile } from "apis/storage.api";

const UploadImage = ({ name, value: image, onChange, loader = true }) => {
  // const { request } = useApi({
  //   api: uploadFile,
  // });
  const [loadingImage, setLoading] = useState(false);

  const beforeUpload = useCallback((file) => {
    const fileName = file.name.split(".");
    const fileExt = fileName[fileName.length - 1];
    if (fileName.length === 1) {
      showError();
      return false;
    }
    const isJpgOrPng = ["png", "jpg", "jpeg"].includes(fileExt.toString().toLowerCase());
    // const isLt1M = file.size / 1024;
    // const limit = isLt1M >= 1024;
    if (!isJpgOrPng) {
      Toast({
        content: !isJpgOrPng ? lang.invalidFileType : lang.unbaleToUploadMoreThanMb,
        error: true,
        icon: "exclamation-fill",
      }).open();
      return false;
    }
    return true;
  }, []);

  const showError = () => {
    Toast({
      content: lang.somethingWentWrong,
      error: true,
      icon: "exclamation-fill",
    }).open();
  };

  const uploadAvatar = useCallback(async (res) => {
    const { onSuccess } = res;
    try {
      if (res && res.fileList) {
        onSuccess(res);
      }
    } catch {
      showError();
    }
  }, []);

  const onChangeImage = useCallback(
    (res) => {
      const reader = new FileReader();
      const file = res.fileList[res.fileList.length - 1].originFileObj;
      reader.readAsDataURL(file);
      let onload = null;
      onload = reader.addEventListener(
        "load",
        async () => {
          const isImageValid = beforeUpload(file);
          if (isImageValid) {
            setLoading(true);
            // const imageUrl = await request({
            //   file: res.file.originFileObj,
            // });
            // if (imageUrl) {
            //   onChange({
            //     image: imageUrl[0].permanentUrl,
            //     value: imageUrl[0].permanentUrl,
            //     file: res.file,
            //   });
            // }
            onChange({
              image: reader.result,
              value: reader.result,
              file: res.file.originFileObj,
            });
            reader.abort();
            reader.removeEventListener("load", onload);
            setLoading(false);
          }
        },
        false
      );
    },
    [onChange, beforeUpload, setLoading]
  );

  const uploadRender = useCallback(
    (element) => {
      return (
        <Upload
          className={classnames({
            [`${styles.uploadContainer}`]: !image,
          })}
          beforeUpload={() => {
            return true;
          }}
          customRequest={uploadAvatar}
          onChange={onChangeImage}
        >
          {element}
        </Upload>
      );
    },
    [uploadAvatar, onChangeImage, image]
  );

  return (
    <div className={classnames(styles.uploadIcon, "m-sm flex items-center")}>
      {uploadRender(
        <div>
          <Tooltip title="Upload Image">
            <span>
              <Icon name="plus" color="text-pelorous" className="m-auto" fontSize="12px" />
            </span>
          </Tooltip>
        </div>
      )}
      {loader ? <Loader loading={loadingImage} /> : null}
    </div>
  );
};

export default UploadImage;
