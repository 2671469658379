import React, { useState } from "react";
import styles from "./select-color.module.scss";
import classNames from "classnames";
import { Field, Icon, Input, Text } from "..";

const SelectColor = ({ value = "", setValue }) => {
  const [active, setActive] = useState(false);

  const colors = [
    "#FF000A,",
    "#FF5F00,",
    "#FFCB00,",
    "#B8D231,",
    "#34D231,",
    "#31D2D2,",
    "#007FFF,",
    "#5F43FF,",
    "#840090,",
    "#DB00FF,",
  ];
  const [bg, fc] = value.split(",");
  return (
    <div>
      <div>
        <div
          className={
            "pn-input flex items-center bg-white border rounded border-solid relative min-h-field px-md justify-between"
          }
          onClick={() => {
            setActive(!active);
          }}
        >
          <div className="">
            {value ? (
              <div className="flex">
                <div
                  className={classNames(styles.color)}
                  style={{
                    background: bg,
                  }}
                ></div>
                <div
                  className={classNames(styles.color, "ml-xs")}
                  style={{
                    background: fc,
                  }}
                ></div>
              </div>
            ) : (
              <Text color="text-blue">Click to select theme</Text>
            )}
          </div>
          <div>
            <Icon name="down-caret" className="m-auto text-gray" />
          </div>
        </div>
      </div>
      {active && <div className={styles.dimmer} onClick={() => setActive(false)}></div>}
      <div
        className={classNames(styles.input, {
          [`${styles.hidden}`]: !active,
        })}
      >
        <div className="grid grid-cols-5">
          {colors.map((color) => {
            const [background] = color.split(",");
            const selected = color.toLowerCase() === value.toLowerCase();
            return (
              <div className={classNames("flex")}>
                <div
                  className={classNames(
                    "flex m-sm cursor-pointer rounded-full",
                    styles.colorContainer,
                    {
                      [`${styles.selectedColor}`]: selected,
                    }
                  )}
                  onClick={() => {
                    setValue(color);
                    setActive(false);
                  }}
                >
                  <div
                    className={classNames(styles.color)}
                    style={{
                      background: background,
                    }}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex m-sm">
          <Field label="Hex">
            <Input
              value={bg}
              onChange={(name, { value }) => {
                setValue([value.trim(), fc].join(","));
              }}
              iconSuffix={
                <div>
                  <div
                    className={classNames(styles.color)}
                    style={{
                      background: bg,
                    }}
                  ></div>
                </div>
              }
            />
            {/* <Input
              className="mt-xs"
              value={fc}
              onChange={(name, { value }) => {
                setValue([bg, value.trim()].join(","));
              }}
              iconSuffix={
                <div>
                  <div
                    className={classNames(styles.color)}
                    style={{
                      background: fc,
                    }}
                  ></div>
                </div>
              }
            /> */}
          </Field>
        </div>
      </div>
    </div>
  );
};

export default SelectColor;
