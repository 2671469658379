import { Button, Modal, Pill, Text, Title, Toast } from "components/commons";
import React from "react";
import * as moment from "moment";
import datetime from "enums/datetime";
import { PillType, StyleType } from "enums";
import locale from "translations";

const ViewWebhook = ({ editModal, data = {}, ...modal }) => {
  const { active, name, url, events = [], authUsername, authPassword } = data;

  const pillType = () => {
    if (active) {
      return PillType.Green;
    }
    return PillType.Gray;
  };

  return (
    <Modal {...modal}>
      <div>
        <div className="border-b border-solid border-pelorous-lightest">
          <div className="flex justify-between items-center">
            <div>
              <Title lg>{name}</Title>
              <Text color="text-gray">Created on {moment().format(datetime.A)}</Text>
            </div>
            <div>
              <Pill type={pillType()}>{active ? "Active" : "Inactive"}</Pill>
            </div>
          </div>
          <div className="flex py-md">
            <Button
              className="mr-md"
              type={StyleType.Secondary}
              onClick={() => {
                editModal(data);
                modal.close();
              }}
            >
              Edit
            </Button>
            <Button
              type={StyleType.Secondary}
              onClick={async () => {
                await navigator.clipboard.writeText(JSON.stringify(data));
                Toast({
                  content: locale.populate(locale.nCopiedInClipboard, [`${name} webhook`]),
                  success: true,
                  icon: "check",
                }).open();
              }}
            >
              Copy Details
            </Button>
          </div>
        </div>
        <div className="border-b border-solid border-pelorous-lightest py-md">
          <Text color="text-gray" fontWeight="font-bold">
            Target URL
          </Text>
          <Text color="text-blue" size="text-sm">
            {url}
          </Text>
        </div>
        {events.length ? (
          <div className="border-b border-solid border-pelorous-lightest py-md">
            <Text color="text-gray" fontWeight="font-bold">
              Triggers
            </Text>
            <div className="grid grid-cols-2 my-md">
              {events.map((event, index) => {
                return (
                  <div key={index}>
                    <Pill className="mb-xs">{event}</Pill>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}

        {authPassword && authUsername ? (
          <div className="grid grid-cols-2 border-b border-solid border-pelorous-lightest py-md">
            <div>
              <Text color="text-gray" fontWeight="font-bold">
                Username
              </Text>
              <Text color="text-blue" size="text-sm">
                {authUsername}
              </Text>
            </div>
            <div>
              <Text color="text-gray" fontWeight="font-bold">
                Password
              </Text>
              <Text color="text-blue" size="text-sm">
                {authPassword}
              </Text>
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default ViewWebhook;
