import React, { useContext } from "react";
import { Text, Avatar } from "..";
import { ProfileContext } from "contexts";
import styles from "./user-nav.module.scss";
import classnames from "classnames";
import lang from "translations";
import { environment } from "environments/environment";

const UserNavB = () => {
  const { profile, loading } = useContext(ProfileContext) || {};
  const { firstName, lastName, email, avatar: profileAvatar } = profile || {};
  const fullName = `${firstName || ""} ${lastName || ""}`.trim();
  const avatar = {
    className: "m-auto",
    phrases: [firstName, lastName],
    loading,
    image: profileAvatar,
  };

  if (!profile) {
    return null;
  }

  return (
    <div className="pt-96 w-full">
      <div className={classnames(styles.borderBottom, "p-lg text-center")}>
        <div className="text-center mb-md">
          <Avatar {...avatar} size={14} width="w-14" height="h-14" />
        </div>
        <div>
          <Text color="text-gray-600" size="text-md" strong className="whitespace-normal">
            {fullName}
          </Text>
        </div>
        <div>
          <Text sm className="whitespace-normal">
            {email}
          </Text>
        </div>
      </div>

      <div>
        <div
          className="py-sm px-md cursor-pointer"
          onClick={() => {
            window.location.href = environment.POUCH_ACCOUNTS;
          }}
        >
          <Text color="text-center text-base text-black">{lang.accountSettings}</Text>
        </div>
        <div
          className="py-sm px-md cursor-pointer"
          onClick={() => {
            localStorage.clear();
            window.location.href = `${environment.POUCH_ACCOUNTS}/logout?redirectUrl=${environment.CONNECTOR_URL}/home`;
          }}
        >
          <Text color="text-center text-base text-black">{lang.logout}</Text>
        </div>
      </div>
    </div>
  );
};

export default UserNavB;
