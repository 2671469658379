export const environment = {
  id: "production",
  production: true,
  URL: "https://connect.pouchnation.com",
  API: "https://api.connect.pouchnation.com",
  API_V3: "https://gw.pouchnation.com",
  AUTH: {
    login: "https://accounts.pouchnation.com/auth",
    register: "https://accounts.pouchnation.com/register",
    cognitoClientId: "35pp0bmfd4djjmt9ut51sglbva",
    clientId: "CONNECTOR",
  },
  FIREBASE: {
    apiKey: "AIzaSyBwTQaxZOqLXOCDe0Aytj8vw9qPB1UkJVo",
    authDomain: "ms-communication-ae098.firebaseapp.com",
    databaseURL: "https://ms-communication-ae098.firebaseio.com",
    projectId: "ms-communication-ae098",
    storageBucket: "ms-communication-ae098.appspot.com",
    messagingSenderId: "139918626078",
    appId: "1:139918626078:web:c8900ad0dffdb794538d0f",
    measurementId: "G-BG2Z7LL2BK",
  },
  MIXPANEL_TOKEN: "763af5c0f625347770feb6a1867ec07c",
  FEATURE_FLAG: {
    clientSideID: "5f7ad02fe750890b17de445e",
  },
  ACTIVE_MQ_HOST:
    "wss://b-02a26402-ef02-4539-8267-bd7552996a10-1.mq.ap-southeast-1.amazonaws.com:61619",
  ACTIVE_MQ_USER: "pnprod",
  ACTIVE_MQ_PW: "pouchprod123!Xd1jd",
  POUCH_ACCOUNTS: "https://accounts.pouchnation.com",
  CONNECTOR_URL: "https://connect.pouchnation.com",
  VENUE_PAGE: "https://venue.pouchnation.com/redirect",
  DOCUMENTATION_URL: "https://pouchconnect.readme.io/reference/getting-started-connect",
};
