import React, { useCallback } from "react";
import { HeaderA } from "components/headers";
import lang from "translations";
import { useApi, useFilter, useModal, useMount, useRouter } from "hooks";
import { ModuleWrapper } from "components/fragments";
import CreateAppModal from "../create-app-modal/create-app-modal";
import { Button, CardList, Pill, Text } from "components/commons";
import { getApplications } from "apis/application.api";
import { Path } from "paths";
import locale from "translations";
import DeleteApp from "../delete-app/delete-app";
import { ApprovalStatus, PillType, StyleType } from "enums";
import { prettifyApprovalStatus } from "services";

const AppList = () => {
  const { history } = useRouter();
  const {
    request,
    result = { data: {} },
    loading = true,
    error,
  } = useApi({
    api: getApplications,
    handleOwnError: true,
  });
  const deleteAppModal = useModal();

  const { content = [], totalElements = 0 } = result.data || {};
  const { filterState, modifyFilters } = useFilter({
    page: 1,
    pageSize: 10,
  });

  const fetchApps = useCallback(
    async (params = {}) => {
      const p = {
        ...filterState,
        ...params,
      };
      return await request({
        page: p.page - 1,
        size: p.pageSize,
      });
    },
    [filterState, request]
  );

  const createAppModal = useModal();

  useMount(() => {
    fetchApps();
  });

  return (
    <div>
      <ModuleWrapper
        error={error}
        header={
          <HeaderA
            title={lang.myApps}
            description={lang.myAppsDesc}
            button={{
              onClick: () => {
                createAppModal.show();
              },
              text: lang.createNewApp,
            }}
            className="mb-md"
          />
        }
      >
        <CardList
          loading={loading}
          total={totalElements}
          pagination={filterState}
          setPagination={({ page, pageSize }) => {
            const { requestState } = modifyFilters({
              page,
              pageSize,
            });
            fetchApps(requestState);
          }}
          actions={[
            {
              text: locale.view,
              onClick: (item) => {
                history.push(Path.APP_VIEW_ID(item.id));
              },
            },
            {
              text: locale.delete,
              onClick: (item) => {
                console.log("DELETE", item);
                deleteAppModal.show({
                  id: item.id,
                  title: locale.populate(locale.askDeleteName, [item.title]),
                });
              },
            },
          ]}
          items={content.map((item) => {
            const [color, fontColor] = item?.colors?.split(",") || [];
            return {
              title: item.displayName,
              description: item.description,
              id: item.id,
              image: item.logoLink,
              status: (
                <Pill
                  type={
                    item.approvalStatus === ApprovalStatus.Enabled
                      ? PillType.Green
                      : PillType.Yellow
                  }
                  className="mb-sm"
                >
                  {prettifyApprovalStatus(item.approvalStatus)}
                </Pill>
              ),
              color,
              fontColor,
              children: (
                <div className="mt-md">
                  <Button
                    type={StyleType.Secondary}
                    onClick={() => {
                      history.push(Path.APP_VIEW_ID(item.id));
                    }}
                  >
                    <Text color="text-pelorous" size="text-xs" fontWeight="font-bold">
                      View Details
                    </Text>
                  </Button>
                </div>
              ),
            };
          })}
        />

        <DeleteApp
          {...deleteAppModal}
          callback={() => {
            const { requestState } = modifyFilters({
              page: 1,
            });
            fetchApps(requestState);
          }}
        />
      </ModuleWrapper>
      <CreateAppModal {...createAppModal} refreshList={fetchApps} />
    </div>
  );
};

export default AppList;
