import React from "react";
import { Title, Text, Button } from "components/commons";
import { useRouter } from "hooks";
import classNames from "classnames";

const HeaderA = ({ title, description, button, className }) => {
  const { history } = useRouter();
  const {
    path,
    text,
    validate,
    disabled: primaryDisabled = false,
    loading: primaryLoading = false,
    onClick,
    iconPrefix,
  } = button || {};
  return (
    <div className={classNames(className, "md:flex items-center justify-between")}>
      <div>
        <Title xl className="font-semibold">
          {title}
        </Title>
        {description && <Text>{description}</Text>}
      </div>
      <div>
        {button && (
          <Button
            loading={primaryLoading}
            disabled={primaryDisabled || primaryLoading}
            className="ml-0 mt-sm md:ml-md mt-0"
            onClick={
              onClick
                ? () => {
                    onClick();
                  }
                : () => {
                    const validated = validate ? validate() : true;
                    if (validated) {
                      history.push(path);
                    }
                  }
            }
            iconPrefix={iconPrefix}
          >
            {text}
          </Button>
        )}
      </div>
    </div>
  );
};

export default HeaderA;
