import React from "react";
import styles from "./homepage.module.scss";
import classNames from "classnames";
import { Button, Image, Text, Title } from "components/commons";
import { AccountingIcon, BoyWorking, CreditCardIcon, GirlWorking, TicketIcon } from "images";
import { StyleType } from "enums";
import { useRouter } from "hooks";
import { Path } from "paths";
import { FragmentMargin } from "components/fragments";
import { LayoutA } from "components/layouts";
import lang from "translations";

const HomePage = () => {
  const { history } = useRouter();
  return (
    <LayoutA paddingless={true}>
      <div className={styles.container}>
        <div className={classNames(styles.headerContainer, "flex items-center")}>
          <FragmentMargin className={classNames("mt-md h-full")}>
            <div className="p-4 lg:p-0 grid grid-flow-col grid-cols-1 grid-rows-2 lg:grid-cols-2 lg:grid-rows-1 items-center gap-4 h-full">
              <div className="order-last md:order-first">
                <div className={classNames("font-mono mb-2.5 mt-md text-4xl md:text-6xl")}>
                  {lang.buildWithPouchConnect}
                </div>
                <div className="max-w-sm md:max-w-lg text-base md:text-lg text-justify">
                  {lang.welcomeToPouchnationDeveloperHub}
                </div>
              </div>

              <div className="h-full">
                <Image src={GirlWorking} className={classNames("h-full max-w-max")} />
              </div>
            </div>
          </FragmentMargin>
        </div>
        <FragmentMargin className={classNames(styles.apis, "p-4 md:p-0")}>
          <div className={classNames(styles.smallTitle)}>{lang.support}</div>
          <div className={styles.h2}>
            <Title xl>{lang.startIntegrating}</Title>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-md">
            {[
              {
                image: TicketIcon,
                title: lang.bookingsApi,
                description: lang.syncBookingsAndReservations,
                link: "https://pouchconnect.readme.io/reference/create-booking",
              },
              {
                image: AccountingIcon,
                title: lang.accountingApi,
                description: lang.synchronizeYourAccounting,
                link: "https://pouchconnect.readme.io/reference/accounting-api",
              },
              {
                image: CreditCardIcon,
                title: lang.paymentApi,
                description: lang.createAndAuthorizeYourOwnPayments,
                link: "https://pouchconnect.readme.io/reference/payments-api",
              },
            ].map((item, index) => {
              return (
                <div
                  key={index}
                  className={classNames(styles.apiItem, "bg-white p-lg text-center")}
                >
                  <div className={styles.apiContent}>
                    <div className={classNames("text-center", styles.iconImage)}>
                      <Image className={styles.image} src={item.image} />
                    </div>
                    <div className="mb-md">
                      <Title xl>{item.title}</Title>
                    </div>
                    <div>
                      <Text>{item.description}</Text>
                    </div>
                  </div>
                  <div className={styles.apisButton}>
                    <Button
                      type={StyleType.Secondary}
                      onClick={() => {
                        window.location.href = item.link;
                      }}
                    >
                      <Text color="text-pelorous" fontWeight="font-bold">
                        {lang.viewDocumentation}
                      </Text>
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </FragmentMargin>
        <div className={classNames("bg-pelorous-lighter flex items-center p-4 lg:p-0 lg:py-16")}>
          <FragmentMargin className={classNames(styles.header)}>
            <div className="grid grid-flow-col grid-cols-1 grid-rows-2 lg:grid-cols-2 lg:grid-rows-1 items-center gap-4 h-full">
              <div>
                <div className={styles.smallTitle}>{lang.support}</div>
                <div>
                  <div
                    className={classNames(
                      "font-mono max-w-sm md:max-w-lg text-2xl md:text-4xl text-justify"
                    )}
                  >
                    {lang.needHelp}
                  </div>
                </div>
                <div>
                  <Text size="text-md mt-md">{lang.askOurTechSupportTeam}</Text>
                </div>
                <div className="mt-md">
                  <Button className={styles.discord}>
                    <div
                      className={styles.discord}
                      onClick={() => {
                        window.open("https://discord.gg/5JaQUq5rU9");
                      }}
                    >
                      {lang.joinUsInDiscord}
                    </div>
                  </Button>
                </div>
              </div>
              <div className="h-full">
                <Image src={BoyWorking} className={classNames("h-full w-96 md:w-full")} />
              </div>
            </div>
          </FragmentMargin>
        </div>
        <div className={classNames(styles.createApp, "h-full flex items-center")}>
          <FragmentMargin className={classNames(styles.createAppContent, "text-center")}>
            <div
              className={classNames("font-mono font-bold text-2xl md: text-4xl mb-md text-white")}
            >
              {lang.readyToBuild}
            </div>
            <Button
              onClick={() => {
                history.push(Path.APP);
              }}
            >
              {lang.createMyApp}
            </Button>
          </FragmentMargin>
        </div>
      </div>
    </LayoutA>
  );
};

export default HomePage;
