import React from "react";
import AppForm from "modules/app/app-form/app-form";
import { useApi } from "hooks";
import { updateApplication } from "apis/application.api";
import { uploadApplicationLogo } from "apis/storage.api";

const EditApp = ({ initialState, id, callback, ...modal }) => {
  const { request, loading } = useApi({
    api: updateApplication,
    params: {
      id,
    },
  });
  const { request: uploadLogo } = useApi({
    api: uploadApplicationLogo,
  });

  return (
    <AppForm
      {...modal}
      isEdit
      loading={loading}
      initialState={initialState}
      callback={callback}
      request={async (params) => {
        if (params.logoFile) {
          params.logoLink = null;
        }
        const res = await request({ ...params });
        if (res && params.logoFile) {
          await uploadLogo({ id: res.data?.id, file: params.logoFile });
        }
        return {
          message: "Updated successfully!",
        };
      }}
    />
  );
};

export default EditApp;
