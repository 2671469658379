import { Icon, Logo, Text } from "components/commons";
import { environment } from "environments/environment";
import { useMount, useRouter } from "hooks";
import { Path } from "paths";
import React from "react";
import { addQueryParam, redirectTo } from "services";

const AuthPage = () => {
  const { query, location } = useRouter();
  const { id_token, access_token, state, pathname, idToken, accessToken, userInfo } = query || {};
  const at = access_token || accessToken;
  const it = id_token || idToken;

  useMount(() => {
    if (userInfo) {
      localStorage.setItem("userInfo", userInfo);
    }
    if (location.hash) {
      // history.push(`${Path.AUTH}?${location.hash.toString().substring(1)}`);
      redirectTo(`${Path.AUTH}?${location.hash.toString().substring(1)}`);
    } else if (it || (at && state)) {
      localStorage.setItem("accessToken", at && state ? at : it);
      redirectTo(pathname || "/home");
    } else if (!localStorage.getItem("accessToken")) {
      const loginUrl = addQueryParam(environment.AUTH.login, {
        clientId: environment.AUTH.clientId,
        cognitoClientId: environment.AUTH.cognitoClientId,
        responseType: "token",
        scope: "booking/user+connector/user+email+openid+profile",
        redirectKey: pathname ? "pathname" : "",
        redirectValue: `${pathname}` || "",
        redirectUrl: `${environment.URL}${Path.AUTH}`,
      });
      redirectTo(loginUrl);
    }
  });

  return (
    <div className="flex w-full h-screen items-center">
      <div className="m-auto text-center">
        <div className="mb-md">
          <Logo />
          <Text>CONNECT</Text>
        </div>
        <div>
          <Icon loading />
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
