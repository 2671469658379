import { Get, Post } from "services";

export const getIntegrations = async (params) => {
  const res = await Get(`integrations`, params);
  return res;
};

// export const activateIntegration = async ({ id, body }) => {
//   const res = await Post(`integrations/${id}/activate`, body);
//   return res;
// };

export const activateIntegration = async (body) => {
  const res = await Post(`integrations/mews/customers/tokens`,
      {
        token: body.accessToken,
        businessId: 1
      },
      {
        headers: {"Content-Type": "application/json"},
      });
  return res;
};

export const getIntegration = async ({id}) => {
  const res = await Get(`integrations/${id}`);
  return res;
};

export const getActivatedIntegrations = async ({ id }) => {
  const res = await Get(`integrations/${id}/activations`);
  return res;
};
