import React from "react";
import styles from "./card.module.scss";
import classNames from "classnames";
import { OptionMenu, Text, Title, Skeleton } from "..";
import Preview from "../preview/preview";

const Card = ({
  loading,
  fontColor,
  title,
  icon,
  image,
  status,
  description,
  actions = [],
  color = "",
  layout,
  hasPreview = true,
  children,
  customAction,
}) => {
  if (layout === "list") {
    return (
      <div className={classNames(styles.container, "bg-white", styles.list)}>
        <div className={classNames(styles.header)}>
          {customAction ? (
            <div className={classNames(styles.actions, "px-xs py-xs")}>{customAction}</div>
          ) : (
            <div className={classNames(styles.actions, "px-xs py-xs")}>
              {!loading && actions.length ? <OptionMenu options={actions} /> : null}
            </div>
          )}
        </div>
        <div className="px-lg flex">
          {hasPreview && (
            <Preview
              className={styles.preview}
              icon={icon}
              image={image}
              title={title}
              color={color}
              loading={loading}
              fontColor={fontColor}
            />
          )}

          {loading ? (
            <Skeleton />
          ) : (
            <div>
              <Title> {title}</Title>

              {description && (
                <Text description>
                  <div className={styles.description}>{description}</div>
                </Text>
              )}
              {children}
            </div>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className={classNames(styles.container, "bg-white")}>
      <div className={classNames(styles.header)}>
        <div className={classNames(styles.actions, "px-xs py-xs")}>
          {!loading && actions && <OptionMenu options={actions} />}
        </div>
        <Preview
          loading={loading}
          className={styles.preview}
          icon={icon}
          image={image}
          title={title}
          color={color}
          fontColor={fontColor}
        />
      </div>
      {loading ? (
        <div className="pb-lg px-lg">
          <Skeleton />
        </div>
      ) : (
        <div className="p-lg mt-xs">
          {status}
          <Title className="mb-sm">{title}</Title>
          {description && (
            <Text description>
              <div className={styles.description}>{description}</div>
            </Text>
          )}
          {children}
        </div>
      )}
    </div>
  );
};

export default Card;
