import React, { useMemo } from "react";
import { Icon, Image, Text } from "..";
import classnames from "classnames";

const ImageContainer = ({ children, className, width, height }) => {
  return (
    <div
      className={classnames(
        className,
        `bg-gray-300 ${height} ${width} rounded-full flex items-center text-center overflow-hidden`
      )}
    >
      {children}
    </div>
  );
};

const Avatar = ({
  image,
  phrases = [],
  defaultIconAvatar = "user",
  className,
  height = "h-11",
  width = "w-11",
  loading,
  textSize,
  size,
}) => {
  const avatarProps = {
    width,
    height,
  };
  const placeholder = useMemo(() => {
    let text = "";
    phrases.forEach((p) => {
      if (p?.[0]) {
        text = text + p[0];
      }
    });
    return text.trim();
  }, [phrases]);

  if (image) {
    return (
      <ImageContainer className={className} {...avatarProps}>
        <Image src={image} className="h-full w-full" />
      </ImageContainer>
    );
  }

  if (placeholder) {
    return (
      <ImageContainer className={className} {...avatarProps}>
        <Text className="m-auto text-white" size={textSize || "text-xl"}>
          {placeholder}
        </Text>
      </ImageContainer>
    );
  }

  return (
    <ImageContainer className={className} {...avatarProps}>
      <Icon
        name={defaultIconAvatar}
        className="m-auto text-white"
        fontSize={`${Number(size) + 15}px`}
        loading={loading}
      />
    </ImageContainer>
  );
};

export default Avatar;
