import React, { useMemo } from "react";
import { Icon, Image, Skeleton, Text } from "..";
import styles from "./preview.module.scss";
import classNames from "classnames";
import { logoIcons } from "services/text.service";

const Preview = ({
  image,
  color,
  title = "",
  className,
  loading,
  fontColor,
  onClick,
  text,
  error,
  useInitials = false,
}) => {
  const preview = useMemo(() => {
    const isIcon = logoIcons.includes(image);
    if (!isIcon && image) {
      return <Image src={image} />;
    }
    return (
      <div className={classNames(styles.initials)}>
        <span
          className={classNames({
            [`${styles.border}`]: !color,
            "border-white-darker": !error,
            "border-red": error,
          })}
          style={{
            color,
          }}
        >
          {text && !image ? <Text label>{text}</Text> : null}
          {isIcon ? (
            <Icon
              name={image}
              fontSize="40px"
              style={{
                color,
              }}
            />
          ) : useInitials ? (
            title[0]
          ) : null}
        </span>
      </div>
    );
  }, [image, color, title, text, error, useInitials]);
  return (
    <div
      className={classNames(styles.preview, className, {
        "cursor-pointer": onClick,
      })}
      onClick={onClick}
    >
      <div
        className={classNames(styles.app)}
        style={{
          backgroundColor: `${color}16`,
        }}
      >
        {loading ? <Skeleton circle /> : preview}
      </div>
    </div>
  );
};

export default Preview;
